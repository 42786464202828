import React from 'react';
import img1 from '../../img/Article5_img1.jpg';

const Post5 = () => {
  return(
    <>
      <p>
        Amazon在前日推出了「亞馬遜全球收款」(Amazon Currency Converter for Sellers)，服務主要便利內地賣家。開通後再不需要開設外國銀行帳戶抑或第三方帳戶，可以直接在國內銀行戶口收款，為國內跨境賣家帶來便利、快速和安全的賣買體驗。服務剛推出，已接受全球40多種貨幣，並可轉互為45種貨幣，手續費為1.25%。目前在亞馬遜九大海外站都能申請開通，包括美國、加拿大、德國、英國、法國、意大利、西班牙、日本和墨西哥。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        亞馬遜中國副總裁、亞馬遜全球開店亞太區負責人戴竫斐表示：「目前，中國賣家在跨境出口金融管理方面存在諸多需求，其中包括多個站點的帳戶管理、跨境收款資金鍊、收款周期、外匯轉換、資金安全等。此次發布的『亞馬遜全球收款』服務旨在幫助中國賣家解決跨境收款難點、提升運營效率以及財務管理效率。『亞馬遜全球收款』為中國賣家拓展全球市場提供更多保障及靈活性，助力中國賣家在『下一代貿易鏈』中贏得更多機遇。」
      </p>
      <p>
        環金科技供應鏈副總裁Jacky Zhao就此服務分別以成本、時效和安全三方面進行分析。在成本方面，相比現有的收款工具，包括Payoneer、WorldFirst，以及PingPong等，1.25%手續費並不占優勢，其中還存在著匯差問題。從時效性的角度分析，「亞馬遜全球收款」收款需3-5天，相比其他工具慢，配套服務上亦沒有競爭優勢。加上大多數賣家都是同是經營多個帳號，若集中使用新服務收款將無可避免被關聯。內行人仕亦向我們表示「亞馬遜全球收款」與他們的銷售客戶大為不同，亞馬遜主要針對個體客戶或業餘賣家，訂單相比使用現有收款工具的用戶少，好處是不必花太多時間在系統上對接，但成本相對地高，再加上在帳戶數目方面設有限制。如此一來，亞馬遜新推出的收費服務並不會對他們的營業額帶來影響。由此可見亞馬遜全球收款對比其他收款工具較遜色，相信絕大多跨境賣家仍處於觀望狀態。
      </p>
    </>
  )
}

export default Post5;