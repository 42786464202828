import React from 'react';
import FooterStyle from '../styles/components/footer.module.scss';
import { Link } from 'react-router-dom';
import cgesImg from '../img/CBES.png'
import { FacebookFilled, LinkedinFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();
  return(
    <div className={ FooterStyle.footer }>
      <div className={ FooterStyle.content_BG }>
        <div className={ FooterStyle.content }>
          <div className={ FooterStyle.cbesgLogo }>
            <a href="https://www.cbesg.com" target="_blank" rel="noreferrer">
              <img src={ cgesImg } alt="cbes-logo" />
            </a>
            <div className={ FooterStyle.socialLink }>
              <a href="https://www.facebook.com/groups/continentalglobal/" target="_blank" rel="noreferrer">
                <FacebookFilled />
              </a>
              <a href="https://www.linkedin.com/in/kei-chuen-stanley-lee-ab7304bb/" target="_blank" rel="noreferrer">
                <LinkedinFilled />
              </a>
            </div>
          </div>
          <div>
            <p>
              <span>{ t('footer.title.phone') }</span><br /> 
              { t('footer.content.phone') }
            </p>
            <p>
              ​<span>{ t('footer.title.email') }</span><br />
              info@contin-global.com（{ t('footer.content.newCustomer') }）<br />
              cs@contin-global.com（{ t('footer.content.existingCustomer') }）
            </p>
          </div>
          <div>
            <p>
              <span>{ t('footer.title.address') }</span><br />
              { t('footer.content.address') }
            </p>
            <p>
              <span>{ t('footer.title.office') }</span><br />
              { t('footer.content.office') }
            </p>
          </div>
        </div>
      </div>
      <div className="wrap">
        <div className={ FooterStyle.pageLink }>
          <Link to="/">{ t('page.home') }</Link>
          <Link to="/service">{ t('page.service') }</Link>
          <Link to="/aboutus">{ t('page.aboutus') }</Link>
          {
            i18n.language === "en" || i18n.language === "jp" ?
            null :
            <Link to="/blog">電商日誌</Link>
          }
          <Link to="/faq">{ t('page.faq') }</Link>
          {
            i18n.language === "jp"?
            null :
            <Link to="/career">{ t('page.career') }</Link>
          }
        </div>
        <div className={ FooterStyle.copyright }>
          Copyright © 2021 Continental Global Service Limited
        </div>
      </div>
    </div>
  )
}

export default Footer
