import React from 'react';
import { Tabs, Collapse } from 'antd';
import FaqStyle from '../styles/pages/faq.module.scss';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const Faq = () => {
  const { t, i18n } = useTranslation();
  const changeTabs = (key) => {
    console.log(key);
  }

  return(
    <>
      <MetaTags>
        <title>
          {  
            i18n.language === 'en' || i18n.language === 'jp' ?
            'Continental Global Service Ltd - One-Stop eCommerce Logistics Service' :
            'Continental Global Service Limited - 一站式跨境電商物流 | 常見問題'
          }
        </title>
      </MetaTags> 
      <div className={ FaqStyle.faq }>
        <div className="banner-color">
          <h1 className="wrap">{ t('page.faq') }</h1>
        </div>
        <div className="wrap content">
          <div className={ FaqStyle.content }>
            <Tabs defaultActiveKey="1" onChange={ changeTabs } className={ i18n.language === 'en' ? 'tabContent en' : 'tabContent' }>
              <TabPane tab={ t('faq.tabTitle.1') } key="1">
                <Collapse 
                  accordion
                  ghost
                  expandIconPosition='right'
                  bordered={ false }
                >
                  <Panel header={ t('faq.tab1.question.1') } key="1">
                    <p>
                      { t('faq.tab1.answer.1.1') }<br />
                      { t('faq.tab1.answer.1.2') }
                    </p>
                  </Panel>
                  <Panel header={ t('faq.tab1.question.2') } key="2">
                    <p>
                      { t('faq.tab1.answer.2') }
                    </p>
                  </Panel>
                  <Panel header={ t('faq.tab1.question.3') } key="3">
                    <p>
                      { t('faq.tab1.answer.3') }
                    </p>
                  </Panel>
                  <Panel header={ t('faq.tab1.question.4') } key="4">
                    <p>
                      { t('faq.tab1.answer.4') }
                    </p>
                  </Panel>
                </Collapse>
              </TabPane>
              <TabPane tab={ t('faq.tabTitle.2') } key="2">
                <Collapse 
                  accordion
                  ghost
                  expandIconPosition='right'
                  bordered={ false }
                >
                  <Panel header={ t('faq.tab2.question.1') } key="1">
                    <p>
                      { t('faq.tab2.answer.1') }
                    </p>
                  </Panel>
                  <Panel header={ t('faq.tab2.question.2') } key="2">
                    <p>
                      { t('faq.tab2.answer.2') }
                    </p>
                  </Panel>
                  <Panel header={ t('faq.tab2.question.3') } key="3">
                    <p>
                      { t('faq.tab2.answer.3') }
                    </p>
                  </Panel>
                  <Panel header={ t('faq.tab2.question.4') } key="4">
                    <p>
                      { t('faq.tab2.answer.4') }
                    </p>
                  </Panel>
                  <Panel header={ t('faq.tab2.question.5') } key="5">
                    <p>
                      { t('faq.tab2.answer.5') }
                    </p>
                  </Panel>
                </Collapse>
              </TabPane>
              <TabPane tab={ t('faq.tabTitle.3') } key="3">
                <Collapse 
                  accordion
                  ghost
                  expandIconPosition='right'
                  bordered={ false }
                >
                  {
                    i18n.language === 'jp' ?
                    null :
                    <Panel header={ t('faq.tab3.question.1') } key="1">
                      <p>
                        { t('faq.tab3.answer.1') }
                      </p>
                    </Panel>
                  }
                  <Panel header={ t('faq.tab3.question.2') } key="2">
                    <p>
                      { t('faq.tab3.answer.2') }
                    </p>
                  </Panel>
                  <Panel header={ t('faq.tab3.question.3') } key="3">
                    <p>
                      { t('faq.tab3.answer.3') }
                    </p>
                  </Panel>
                  <Panel header={ t('faq.tab3.question.4') } key="4">
                    <p>
                      { t('faq.tab3.answer.4') }
                    </p>
                  </Panel>
                  {
                    i18n.language === 'jp' ?
                    null :
                    <Panel header={ t('faq.tab3.question.5') } key="5">
                      <p>
                        { t('faq.tab3.answer.5') }
                      </p>
                    </Panel>
                  }
                </Collapse>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faq