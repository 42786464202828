import React from 'react';
import img1 from '../../img/Article26_img1.jpg';
import img2 from '../../img/Article26_img2.jpg';
import img3 from '../../img/Article26_img3.jpg';
import img4 from '../../img/Article26_img4.jpg';
import img5 from '../../img/Article26_img5.jpg';
import img6 from '../../img/Article26_img6.jpg';

const Post26 = () => {
  return(
    <>
      <img src={ img1 } alt="img1" />
      <p>
        究竟中國人做生意可以做到多遠、多深?
      </p>
      <p>
        在世界各地到處都可以看到中國人的足跡，舉凡在美國大城市都有唐人街(Chinatown) ，中國人主理的餐廳或各式各樣商店林立在街道。早期到海外創業的中國人可能經歷過許多艱辛的時刻，他們除了需要離鄉背井，還需要克服語言和文化上的差異。隨著時日的變遷，唐人街已成為華人在美國的發跡地，不僅隨街都是中國人，甚至成為他們在當地工作或開店的選址。
      </p>
      <img src={ img2 } alt="img2" />
      <p>
        早年不少人認為義大利服飾及皮革比較出眾，中國人懂得審時度勢到當地尋找設計師和生產原材料，在當地設立工廠，再把他們的生產模式一整套搬到當地。有趣的是中國人的生意觀念和其他國家不同，猶太人喜歡在同一個地方開展不同的行業，比喻說第一個猶太人開了服飾店，第二個猶太人會選擇營業餐廳；第三個為了避免生意上的鬥爭而發展超市。而中國人卻完整承傳了百家爭鳴的傳統，更傾向於在同一個地方發展同一個行業，務求壟斷整個行業。
      </p>
      <img src={ img3 } alt="img3" />
      <p>
        阿里巴巴便是一個例子，在淘寶上有各式各樣、琳琅滿目的商品，但仔細一看其實不少商店在販賣一模一樣的產品。是次我有幸參加阿里巴巴集團底下全球速賣通（AliExpress）在義大利舉辦的商家大會，活動當中阿里巴巴提及到他們在中國現行的網購模式非常成功，是時候拓展集團多國事業，例如義大利。先把整套系統複製到當地，取得義大利的華商、僑領的支持後，再找懂得中文的外國人來穿針引線發展跨境和本地的電子商務。
      </p>
      <img src={ img4 } alt="img4" />
      <p>
        雖然我不認為中國人在創業做生意的每一步都能夠成功，但不可否認的是他們的每一步都跨得很快、很大。然而中國人在世界各地發展急促的主因，不僅是因為人數眾多，而是他們的野心和生意頭腦，這些都是我們都需要尊敬和學習的地方。
      </p>
      <img src={ img5 } alt="img5" />
      <img src={ img6 } alt="img6" />
    </>
  )
}

export default Post26;