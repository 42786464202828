import React, { useState, useContext } from 'react';
import { Form, Input, Button, Radio, message } from 'antd';
import SignupStyle from '../styles/pages/signup.module.scss';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { config } from '../config/token';
import { LoadingContext } from '../components/_App';

const Signup = () => {
  const loadingContext = useContext(LoadingContext);
  const [ recaptchaToken, setRecaptchaToken ] = useState('');
  const { t, i18n } = useTranslation();
  const [ GenderValue, setGenderValue ] = useState();
  const validateMessages = {
    required: '此為必填欄位!',
    types: {
      email: '不是正確的信箱格式!'
    }
  };

  const onChangeGender = e => {
    setGenderValue(e.target.value);
    console.log(e.target.value);
  };
  
  const onChange = value => {
    // console.log('Captcha value:', value);
    setRecaptchaToken(value);
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': `${ config.token }`
  }

  const [form] = Form.useForm();

  const onFinish = ( values ) => {
    values = { ...values, type: "signup" };
    // console.log(values);
    loadingContext.handleLoading();
    axios.post('/api/send', { 'data': values }, { headers: headers })
    .then(res => {
      if(res.status === 200){
        if(!recaptchaToken || recaptchaToken === undefined){
          recaptchaErrorMsg();
          loadingContext.handleNotLoading();
        } else {
          successMsg();
          loadingContext.handleNotLoading();
          onReset();
          window.grecaptcha.reset();
        }
      }
    })
    .catch(error => {
      console.log(error);
      // console.log(recaptchaToken);
      if(!recaptchaToken || recaptchaToken === undefined){
        recaptchaErrorMsg();
        loadingContext.handleNotLoading();
      } else {
        errorMsg();
        loadingContext.handleNotLoading();
        onReset();
        window.grecaptcha.reset();
      }
    })
  };

  const onReset = () => {
    form.resetFields();
  };

  const successMsg = () => {
    message.success('留言發送成功！')
    .then(() => window.location.reload());
  };

  const errorMsg = () => {
    message.error('留言發送失敗！')
    .then(() => window.location.reload());
  };

  const recaptchaErrorMsg = () => {
    message.error('請勾選 Recaptcha 核取方塊');
  }

  return(
    <>
      <MetaTags>
        <title>
          {  
            i18n.language === 'en' || i18n.language === 'jp' ?
            'Continental Global Service Ltd - One-Stop eCommerce Logistics Service' :
            'Continental Global Service Limited - 一站式跨境電商物流 | 立即登記'
          }
        </title>
      </MetaTags> 
      <div className={ SignupStyle.signup }>
        <div className="banner-color">
          <h1 className="wrap">{ t('page.signup') }</h1>
        </div>
        <div className="wrap content">
          <div>
            <div className={ SignupStyle.form }>
              <Form name="contact" onFinish={ onFinish } validateMessages={ validateMessages }>
                <Form.Item label={ t('signup.name') } name={['name']} rules={[{ required: true }]}>
                  <Input size="large" />
                </Form.Item>

                <Form.Item label={ t('signup.gender') } name={['gender']} rules={[{ required: true }]}>
                  <Radio.Group onChange={ onChangeGender } value={ GenderValue }>
                    <Radio value="male">{ t('signup.gender.male') }</Radio>
                    <Radio value="female">{ t('signup.gender.female') }</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label={ t('signup.email') } name={['email']} rules={[{ type: 'email', required: true }]}>
                  <Input size="large" />
                </Form.Item>

                <Form.Item label={ t('signup.cellphone') } name={['mobile_number']} rules={[{ required: true }]}>
                  <Input size="large" />
                </Form.Item>

                <Form.Item label={ t('signup.companyName') } name={['company_name']} rules={[{ required: false }]}>
                  <Input size="large" />
                </Form.Item>

                <Form.Item label={ t('signup.companyAddress') } name={['company_position']} rules={[{ required: false }]}>
                  <Input size="large" />
                </Form.Item>

                <Form.Item label={ t('signup.companyPhone') } name={['company_phone']} rules={[{ required: false }]}>
                  <Input size="large" />
                </Form.Item>

                <Form.Item label={ t('signup.enquiries') } name={['content']} rules={[{ required: true }]}>
                  <Input size="large" />
                </Form.Item>

                <ReCAPTCHA
                  sitekey={`${config.recaptcha_sitekey}`}
                  onChange={ onChange }
                />

                <div className={ SignupStyle.submitBtn }>
                  <Button type="primary" htmlType="submit">{ t('signup.send') }</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Signup