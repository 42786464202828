import React from 'react';

const Post22 = () => {
  return(
    <>
      <p>
        <span>中國產品在這裡很受歡迎</span>
      </p>
      <p>
        作為歐洲第五大電商市場，西班牙雖然缺少像淘寶那樣涵蓋各個品類的大平臺，但是並不缺乏網購網站，電商發展還是較為成熟的。
      </p>
      <p>
        Ecommerce Foundation的西班牙國家電商報告顯示，2016年，西班牙的電商交易額為239億歐元，有54%的西班牙人進行網購，他們買得最多的是服裝和鞋類，其次是家用電子產品、書籍、運動和體育用品。
      </p>
      <p>
        預計<span>今年西班牙的電商零售額將增長至280億歐元左右，增長率達17%</span>，網購用戶在總人口中所占的比例也穩步上升，將達到62%。在電商零售中，服裝和體育用品是實體商品中的最大品類，占比31%。
      </p>
      <p>
        然而，西班牙製造業比較弱且不齊全，即使是在時尚業，雖然有Zara、Mango這樣的國際大品牌，但這些都是快時尚商品，不能滿足所有服飾方面的訴求。因此，當地消費者對豐富的便宜好貨有很大需求。
      </p>
      <p>
        眾所周知，中國有很多便宜好貨。西班牙駐滬總領館經濟商務參贊方少龍（Alfonso Norieag Gomez）表示：“中國的產品在西班牙很受歡迎。”
      </p>
      <blockquote>
        西班牙人愛吃愛玩愛社交，對價格便宜、款式新穎的“新奇特”產品都很感興趣。像來自中國的手機殼、資料線、運動相機、雲台、平衡車，各種色彩的指甲油、假睫毛等，在西班牙常年都很熱銷，太陽眼鏡則是夏天的熱銷商品。
      </blockquote>
      <p>
        <span>速賣通成他們最愛的購物APP</span>
      </p>
      <p>
        報告還顯示，在西班牙，最受歡迎的移動購物APP是Wallapop和速賣通。Wallapop是西班牙第一大二手商品網站，而速賣通則是西班牙第一大跨境電商平臺，平臺上豐富的性價比高的商品正好滿足了西班牙人的需求。
      </p>
      <p>
        據瞭解，速賣通在西班牙的滲透率很高，幾乎人人都知道“AliExpress”。目前，<span>速賣通在西班牙擁有超過770萬註冊用戶，APP下載量突破千萬</span>。在Facebook、Twitter、Instagram等社交媒體上，速賣通的西班牙粉絲近200萬，這些粉絲還自發組建了社交群組，分享購物心得與體驗。
      </p>
      <blockquote>
        在速賣通上，最受西班牙人歡迎的商品有：波西米亞服裝、運動愛好品、獨特的家裝、好玩的寵物商品、小米平衡車、Ilife掃地機器人等。
      </blockquote>
      <p>
        <span>“跨境優品”日訂單增至平常的10倍</span>
      </p>
      <p>
        除了提供豐富的商品，速賣通還提升了西班牙消費者的購物體驗，最明顯的就是物流時效的提升。
      </p>
      <p>
        速賣通不僅與西班牙郵政有合作，還與菜鳥合作建立了西班牙海外倉，並於今年1月推出了無憂物流專線。從中國發出的包裹，配送時間由過去的30天縮短到10-12個工作日，海外倉及西班牙本地賣家發出的包裹，最快當日就能送達，3天內100%送達。
      </p>
      <p>
        此外，速賣通還計畫與西班牙最大的零售集團英吉利百貨進行線上線下的融合合作。據悉，在西班牙全境，英吉利擁有94家百貨商場、6700多個自提點，部分商品可實現2小時送達。
      </p>
      <p>
        另一方面就是產品品質的提升與當地語系化。
      </p>
      <p>
        2015年底，速賣通發佈全平臺入駐門檻新規，進行自身的轉型升級，優勝劣汰，清理了一批品質不好的產品和表現不好的商家，商品的整體品質得以提升。
      </p>
      <p>
        而在速賣通西班牙本地平臺Plaza上，不僅有小米、華為、聯想等中國品牌以及寶潔、歐樂B、迪士尼等國際品牌，還有ECI、BQ等西班牙本土品牌。除了物流時效的保障，平臺上的商品有15天的退貨保障，電子產品有2年的品質保證。
      </p>
      <blockquote>
        今年7月，速賣通還在西班牙推出了“跨境優品”策略，也就是將具有西班牙特色、匹配西班牙人需求的高品質商品推送到他們的面前。
      </blockquote>
      <p>
        具體而言，就是通過資料發掘、網紅或商家推薦選擇出爆品，在核心頻道進行限時限量秒殺。待商品爆發之後，拆分商品的屬性，歸納出共同點，把具有這些共同點的商品集中起來，放到買家最常看到的導購路徑上。
      </p>
      <p>
        如此一來，西班牙消費者看到的就是自己喜歡的商品，商品轉化率自然而然就會提升。<span>在今年的828大促上，這些“跨境優品”的訂單量是平常日訂單量的10倍，占西班牙一天包裹量的10%</span>。
      </p>
      <p>
        三四年來潛移默化，西班牙人對速賣通的認知慢慢發生了改變，從遠在萬里的平臺變成了身邊的平臺。與此同時，西班牙出口局（ICEX）、當地電商協會等政·府部門和民間機構也很歡迎速賣通。
      </p>
      <p>
        在今年的雙11期間，西班牙郵政也會與速賣通一起“狂歡”，消費者在每一個郵寄點都能看到“AliExpress”。而在英吉利百貨，消費者還能看到速賣通的快閃店，當天就能買，1-3天內就能送到。
      </p>
      <p>
        轉載至<a href="https://mp.weixin.qq.com/s/ofD5C2X_wgDM15AIdb-S0A" target="_blank" rel="noreferrer">https://mp.weixin.qq.com/s/ofD5C2X_wgDM15AIdb-S0A</a>
      </p>
    </>
  )
}

export default Post22;