import React from 'react';

const Post21 = () => {
  return(
    <>
      <p>
        每年的旺季到來之前，亞馬遜都會對平臺上商品的安全性和相關證書進行審核。幾天前，亞馬遜郵件通知部分賣家，要求其在10月23日之前提供玩具類認證證書，否則將移除產品庫存。幾天後，鋰電池產品也收到了審核通知
      </p>
      <p>
        昨天，一位亞馬遜歐洲站賣家收到了一封“有關圈柱形鋰離子電池的重要資訊”通知。亞馬遜在郵件中表示：
      </p>
      <blockquote>
        我們此次聯繫您，是因為我們的記錄顯示您可能正在銷售圓柱形鋰離子電池。亞馬遜將圓柱形鋰離子電池定義為一種具有高能量密度且常用於消費類電子產品和其他商品的機電裝置。為確保買家的安全，自2018年10月18日起，亞馬遜將移除下述尺寸的圓柱形鋰離子電池商品資訊，您將不能再銷售此類商品。我們不接受此類商品的銷售申請。受影響的獨立圓柱形電池尺寸- 14500、16340、18650、20700、 21700 和26650。請參閱“電子產品”説明頁面，瞭解更多資訊。亞馬遜物流賣家請注意：如果您在亞馬遜運營中心有圓柱形理離子電池庫存，請在30天內請求將其移除。
      </blockquote>
      <p>
        對於該賣家發出的這一郵件，有賣家確認“確實有這項政策，都不給賣了”。此類商品的銷售似乎進了死胡同，但截至目前，仍有賣家在銷售這類商品。
      </p>
      <p>
        帶電產品的安全問題素來是平臺及賣家非常重視的，鋰電池更是帶電產品中的重點。
      </p>
      <p>
        2017年4月，DHL香港長沙灣倉庫發生火災，事故原因疑為有物流商不合規運送帶電貨物。根據相關發件建議，敦煌網在當時發佈了使用香港DHL倉庫出口鋰電池的注意事項：
      </p>
      <blockquote>
        1. 鋰電池作為獨立的貨品包裝並運輸，鋰電池必須以單獨物品(散裝/集裝)的形式進行包裝和運輸。例如：散裝電池/充電寶；
      </blockquote>
      <blockquote>
        2. 鋰電池單獨包裝但是與設備放在同一個包裝盒中進行運輸。譬如：使用可充電鋰電池的手機；
      </blockquote>
      <blockquote>
        3. 鋰電池包含或安裝在設備中，同時在同一個包裝盒中進行運輸。譬如：安裝了集成鋰電池的平板電腦，其電池不可由用戶拆除或替換。
      </blockquote>
      <p>
        對於其他平臺賣家來說，這些安全措施也有很大的借鑒意義。
      </p>
      <p>
        轉載至<a href="http://www.ennews.com/article-10162-1.html" target="_blank" rel="noreferrer">http://www.ennews.com/article-10162-1.html</a>
      </p>
    </>
  )
}

export default Post21;