import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Avatar } from 'antd';
import BlogStyle from '../styles/pages/blog.module.scss';
import Blog_img from '../img/Blog_img.jpg';
import AvatarImg from '../img/Avatar.jpg';
import blogList from '../config/blogList';
import MetaTags from 'react-meta-tags';

const { Meta } = Card;

const Blog = () => {
  const gridStyle = {
    width: '50%',
    boxShadow: 'none',
  };

  const gridImgStyle = {
    width: '100%'
  }

  return(
    <>
      <MetaTags>
        <title>
          Continental Global Service Limited - 一站式跨境電商物流 | 電商日誌
        </title>
      </MetaTags>
      <div className={`blog ${BlogStyle.blog}`}>
        <div className="banner-color">
          <h1 className="wrap">電商日誌</h1>
        </div>
        <div className="wrap content">
          <div>
            <div className={ BlogStyle.img }>
              <img src={ Blog_img } alt="Blog_img"/>
            </div>
            <div>
              <Row justify="center">
                {
                  [...blogList].reverse().map(item => (
                    <Col span={22} key={ item.key }>
                      <Card style={{ marginTop: 30 }}>
                        {
                          item.image == null ?
                          null : 
                          <Card.Grid 
                            hoverable={ false }
                            className={ BlogStyle.itemImg }
                            style={{...gridStyle, padding: 0}}
                          >
                            <Link to={`/blog/${item.key}`}>
                              <img
                                alt={ item.title }
                                src={ item.image.default }
                                style={ gridImgStyle }
                              />
                            </Link>
                          </Card.Grid>
                        }
                        <Card.Grid 
                          hoverable={ false } 
                          className={ BlogStyle.itemContent }
                          style={ item.image == null ? 
                            {...gridStyle, width: '100%'} 
                            : 
                            {...gridStyle, padding: '1.5rem 2rem'} 
                          }
                        >
                          <Meta
                            title={
                              <Link to={`/blog/${item.key}`}>
                                <h2>{ item.title }</h2>
                              </Link>
                            } 
                            description={ item.description }
                            avatar={
                              <div className="avatar">
                                <Avatar src={ AvatarImg } />
                                <div className="info">
                                  <span className="name">Stanley Lee</span>
                                  <span className="time">{ item.time }</span>
                                </div>
                              </div>
                            }
                          />
                        </Card.Grid>
                      </Card>
                    </Col>
                  ))
                }
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog