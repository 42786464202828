import React from 'react';
import img1 from '../../img/Article30_img1.jpg';
import img2 from '../../img/Article30_img2.jpg';
import img3 from '../../img/Article30_img3.jpg';
import img4 from '../../img/Article30_img4.jpg';
import img5 from '../../img/Article30_img5.jpg';

const Post30 = () => {
  return(
    <>
      <img src={ img1 } alt="img1" />
      <p>
        Continental 大陸集團雖在多數人的印象是輪胎品牌，不過他們在新一代無人車技術也相當積極投入，在今年 <a href="https://www.cool3c.com/tag/114970" target="_blank" rel="noreferrer">CES</a> ，他們發表了一項一車兩用的無人車兼無人<a href="https://www.cool3c.com/tag/23297" target="_blank" rel="noreferrer">快遞</a>的概念，透過把自駕無人車搭配機器狗，在無人車需求的離峰時間可作為快遞服務，提升無人化技術與無人車的附加價值。
      </p>
      <p>
        根據Continental的調查顯示，貨運、快遞往住宅區的市場正逐年提高，由於送往住宅區的多半可能是急件，這也意味著運輸成本變得越來越重要，這也就是Continental要提出CUbE (Urban Mobility Experience )自動物流巴士的主因，首先人力成本甚至更大於運輸成本，二來也可以解決運輸業長期缺工的情形。
      </p>
      <img src={ img2 } alt="img2" />
      <p>
        外型如接駁巴士的CUbE擁有高度的自動化駕駛為基礎，而且為了對應零排放的需求全面採用電氣化，當自動物流巴士按照客戶要求前往指定地點送件或收件，也由於他可以一天工作24小時的連續工作不間斷，不僅促使私家車的需求量大減，也能大大降低都市雍塞、廢氣排放、交通意外的情形發生。
      </p>
      <img src={ img3 } alt="img3" />
      <p>
        今年此項計畫利用稱為 CUBE 的無人車作為全自動駕駛車輛，搭配 ANYmal 狗型四足<a href="https://www.cool3c.com/tag/7339" target="_blank" rel="noreferrer">機器人</a>構成，此項計畫的出發點是考慮到無人車發展成熟後，人們會習慣透過無人車通勤在早晚的需求較高，而在上下班時間之外則是離峰時間、無人車可能就會閒置，故在搭配 ANYmal 狗型四足機器人後，能使無人車在這些離峰時間變成無人化的快遞運送之用。此時以往的物流士並不需要完全參與貨物的運送，而是可以交由內建的多個機器狗來擔任起搬運工的角色，省時省力，同時解決人力短缺的問題。
      </p>
      <img src={ img4 } alt="img4" />
      <p>
        然而 ANYmal 的狗型機器人是大陸集團與蘇黎世技術大學共同開發，除了考慮對各種地形的適應性，狗型設計的背上也可作為成載貨物之用，搭配先進 AI 技術即可提供自動化的輸送服務，能夠拿取或是取箱子，最後也完成無人化物流的最後一哩路。
      </p>
      <img src={ img5 } alt="img5" />
    </>
  )
}

export default Post30;