import React from 'react';
import NavbarStyle from '../styles/components/navbar.module.scss';
import logoImg from '../img/contin-logo.jpg';
import MenuComp from './MenuComp';

const Navbar = () => {
  return(
    <div className={ NavbarStyle.navbar }>
      <div className="wrap flex justify-between align-center">
        <div>
          <img src={ logoImg } alt="contin-logo" />
        </div>
        <MenuComp mode="horizontal" />
      </div>
    </div>
  )
}

export default Navbar;