import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../img/Article16_img1.jpg';
import img2 from '../../img/Article16_img2.jpg';
import img3 from '../../img/Article16_img3.jpg';
import img4 from '../../img/Article16_img4.jpg';
import img5 from '../../img/Article16_img5.jpg';

const Post16 = () => {
  return(
    <>
      <p>
        在<Link to='/blog/15'>哪個跨境電商平台最合適？（上）</Link>我們已分享了4個跨境平台，包含Amazon、eBay、Walmart和Etsy。本篇繼續分享4個跨境電商平台。
      </p>
      <p>
        <span>Shopify</span><br />
        現時全球超過40萬家中小企都使用Shopify的服務，讓商家能夠客製化自已的品牌電商網站。使用Shopify建立專屬的電商網站最吸引地方，便是能夠與Amazon的帳號串連。這意味著當你在Shopify建立的網站接收到訂單，Amazon倉庫會直接出貨，反過來你亦能夠把Rakuten的商品目錄同步到你的Amazon帳號上，不需為Amazon上架作重複工序。除了Amazon外，Shopify同時與多個銷售平台連接，用家可以從Shopify跟踪所有平台上的產品，訂單和客戶。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        <span>Rakuten</span><br />
        樂天市場是一家在日本較常用的網上銷售平台，在日本市場擁有高達9 位數的會員人數。樂天集團旗下有電子商務(EC)、金融科技、數位內容以及電信等超過70種的服務並有12億以上的使用者遍布全球，利用樂天會員的制度做為中心將這些服務相互串起，創造出了獨有的「樂天生態圈」。若希望在樂天市場為你的貨物上架需要有當地的銀行帳號，審核過程相對其他平台嚴謹。
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        <span>Wish</span><br />
        這個平台較重視產品的資訊多於賣家資訊，若要了解更多賣家，需要層層點入才能得出答案，這個設計是希望把商品最重要的訊息帶給用戶。換言而之，在Wish的平台上銷售往往以較低價錢吸引買家，所以平台上的商品價格低得令人無法置信。其中一個原因是賣家只有把價格調低的權利，不能在後台操作漲價。順帶一提的是，Wish在app store行動端上下載掛名僅次於Amazon，低。廉的價錢吸引了不少買家到Wish的平台上消費。
      </p>
      <img src={ img3 } alt="img3"/>
      <p>
        <span>Lazada</span><br />
        作為東南亞第一大大的電商平台，Lazada業務範圍覆蓋印度尼西亞，馬來西亞，菲律賓，新加坡，泰國和越南六個東南亞國家，覆蓋大約6億消費者。如賣家希望入駐Lazada，基本跟亞馬遜美國站註冊資料類似，擁有公司營業執照便可。在收費方面，平台會根據不同類別收取不同成交費用，目前是6%-10%不等。現時平台的規模與淘寶7年前的處境差不多，正處於萌芽期。
      </p>
      <img src={ img4 } alt="img4"/>
      <p>
        <span>Shopee</span><br />
        又名蝦皮購物，是近年急促發展的網上銷售平台。總部設立於新加坡，目前擴展到馬來西亞、菲律賓、泰國、越南、台灣、印尼等地區。近年東南亞手機的普及令電子商務逐漸萌芽，雖說現時還不及其他發展國家，但2025年東南亞地區將會成為電子商務的主要市場。Shopee本只有手機版，直至2015年才建立網頁版，介面與阿里巴巴的淘寶類近。這個網上銷售平台的最大優勢在於郵費大多是免費，而且亦無任何上架費或手續費，大大減低賣家運作成本。
      </p>
      <img src={ img5 } alt="img5"/>
      <p>
        我們已分析了9個大型跨境電商平台，是時候為你的實體商店或網上商店踏出一大步。歡迎與我們聯絡為你提供一站式跨境電商服務。
      </p>
    </>
  )
}

export default Post16;