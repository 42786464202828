import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-router';
import NavbarMobileStyle from '../styles/components/navbarMobile.module.scss';
import logoImg from '../img/contin-logo.jpg';
import MenuComp from '../components/MenuComp';
import { useTranslation } from 'react-i18next';

const NavbarMobile = () => {
  const location = useLocation();
  const match = useRouteMatch("/blog/:postId");
  const { t, i18n } = useTranslation();
  const [ visible, setVisible ] = useState(false);
  const openDrawer = () => {
    setVisible(true);
  }

  const closeDrawer = () => {
    setVisible(false);
  }

  const handleClick = () => {
    setVisible(false);
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return(
    <div className={ NavbarMobileStyle.navbarMobile }>
      <div className="wrap flex justify-between align-center">
        <div>
          <img src={ logoImg } alt="contin-logo" />
        </div>
        <Button className={ NavbarMobileStyle.toggleBtn } onClick={ openDrawer } >
          <MenuOutlined />
        </Button>
        <Drawer visible={ visible } closable={ false } onClose={ closeDrawer }>
          <MenuComp mode="inline" onClick={ handleClick } />
          <div className={ NavbarMobileStyle.langSelect }>
            <svg preserveAspectRatio="xMidYMid meet" data-bbox="43 43 114 114" viewBox="43 43 114 114" height={15} width={15} xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true"><defs></defs>
              <g>
                <path d="M99.999 43C68.57 43 43 68.57 43 100s25.57 57 56.999 57S157 131.43 157 100s-25.572-57-57.001-57zm-48.587 57c0-1.723.094-3.427.27-5.106 4.069-3.703 12.211-7.377 23.652-9.638a145.705 145.705 0 0 0-.738 14.713c0 5.074.256 10.028.745 14.775-11.446-2.261-19.59-5.937-23.659-9.643a48.558 48.558 0 0 1-.27-5.101zm31.594-.031c0-5.772.341-11.121.918-16.046a137.8 137.8 0 0 1 16.046-.918c5.797 0 11.165.343 16.104.925.58 4.925.919 10.269.919 16.039 0 5.794-.341 11.161-.925 16.097-4.936.582-10.302.925-16.097.925-5.77 0-11.114-.339-16.039-.918a137.305 137.305 0 0 1-.926-16.104zm41.658-14.706c11.477 2.276 19.62 5.973 23.666 9.698a49.466 49.466 0 0 1 0 10.071c-4.047 3.728-12.191 7.427-23.67 9.701.486-4.742.745-9.692.745-14.764-.001-5.05-.257-9.977-.741-14.706zm20.937-2.038c-5.957-2.918-13.544-5.208-22.123-6.695-1.478-8.568-3.759-16.153-6.664-22.115 13.301 4.921 23.877 15.504 28.787 28.81zm-40.496-31.543c3.703 4.069 7.377 12.213 9.64 23.657a145.41 145.41 0 0 0-14.775-.745c-5.052 0-9.986.256-14.712.74 2.261-11.442 5.933-19.584 9.636-23.653 1.679-.176 3.382-.27 5.105-.27s3.427.095 5.106.271zm-21.921 2.731C80.281 60.372 78 67.955 76.522 76.521c-8.568 1.478-16.148 3.759-22.108 6.666 4.917-13.29 15.485-23.858 28.77-28.774zm-28.771 62.398c5.962 2.907 13.548 5.186 22.121 6.666 1.485 8.579 3.774 16.166 6.693 22.124-13.308-4.908-23.894-15.487-28.814-28.79zm40.552 31.518c-3.726-4.044-7.424-12.189-9.701-23.666 4.729.484 9.656.74 14.706.74 5.072 0 10.022-.259 14.764-.747-2.274 11.482-5.973 19.627-9.698 23.673a49.28 49.28 0 0 1-10.071 0zm21.809-2.728c2.921-5.96 5.208-13.549 6.693-22.135 8.586-1.485 16.177-3.772 22.134-6.693-4.912 13.319-15.506 23.914-28.827 28.828z" fill="#2F54DD" data-color="1"></path>
              </g>
            </svg>
            <ul>
              <li><button value="zh-TW" onClick={() => changeLanguage('zh-TW')} className={ i18n.language === "zh-TW"? NavbarMobileStyle.active : null}>ZH</button></li>
              {
                location.pathname === '/career' ?
                <li><button value="en" onClick={() => changeLanguage('en')} className={ i18n.language === "en"? NavbarMobileStyle.active : null}>EN</button></li>
                :
                location.pathname === '/remote' || location.pathname === '/blog' || ( match && match.path ) === '/blog/:postId' || location.pathname === '/logistic' ?
                null :
                <>
                  <li><button value="en" onClick={() => changeLanguage('en')} className={ i18n.language === "en"? NavbarMobileStyle.active : null}>EN</button></li>
                  <li><button value="jp" onClick={() => changeLanguage('jp')} className={ i18n.language === "jp"? NavbarMobileStyle.active : null}>JP</button></li>
                </>
              }
            </ul>
          </div>
          <div className={ NavbarMobileStyle.btnGroup }>
            <Button onClick={ handleClick }>
              <Link to="/signup">{ t('page.signup') }</Link>
            </Button>
            <Button onClick={ handleClick }>
              <a href="http://erp.contin-web.com/" target="_blank" rel="noreferrer">
                { t('page.login') }
              </a>
            </Button>
          </div>
        </Drawer>
      </div>
    </div>
  )
}

export default NavbarMobile;