import React from 'react';
import img1 from '../../img/Article24_img1.jpg';
import img2 from '../../img/Article24_img2.jpg';

const Post24 = () => {
  return(
    <>
      <img src={ img1 } alt="img1" />
      <p>
        在電商上，老牌零售沃爾瑪(Walmart)常年被亞馬遜擠壓，實施了一份計畫長達8年，在將來還即將繼續擴大。自2016年亞馬遜和Whole Food合併以來，對於產業線上、線下零售商都有很大的衝擊。不過在今年8、10月份，沃爾瑪宣布分別以160億美元收購印度最大電商Flipkart以及墨西哥電商配送服務平台，用來拓展國際市場和鞏固拉丁美洲的線下物流能力，在近期沃爾瑪收購電商企業的消息也從不間斷。據統計，沃爾瑪自從2010年成立電子商務部門後，至今為止在全球進行了大約26筆電商相關的投資和收購。專家也看出沃爾瑪電商之路的發展策略，採取先橫向收購電商平台取的市場，再垂直整合電商品牌以提升該領域的競爭力。
      </p>
      <p>
        沃爾瑪執行長表示，企業正著手於基礎研究，如何有較合適的庫存，使消費者更容易發現自己的需求並在更低廉的價格下盡快成交，這些數據將代表沃爾瑪客戶價值指數。不過由於這些研究需要大量資金投入，也壓抑了沃爾瑪的獲利，專家也預期在明年2019將有個位數百分比的縮減。不過目前來看，沃爾瑪銷售額已經連續第16季攀升。近期沃爾瑪的股價稍微下滑2個百分比，不過今年初還是比去年上漲了10%，主要的原因還是來自環境的不穩定，比如關稅和匯率的浮動威脅著沃爾瑪。
      </p>
      <p>
        根據國外知名市調網站eMarketer的數據，今年沃爾瑪為全球第三大的電子商務零售商，預計將占4%市占率，約209.1億美元。第一名當然還是電商之王亞馬遜，具有將近48%的市占率，然而第二的eBay僅7.2%。不過在沃爾瑪的業務成長相當可觀，有將近39.4%的成長率，僅落後Wayfair的40.1%。eMarketer更進一步指出，除了由於智慧型手機市場趨近飽和，蘋果電商業務受到不少的影響外，沃爾瑪持續進行聰明的收購，並增加不少產品組合，吸引更多年輕、富裕的消費族群，也採用對於消費者更方便的郵寄到取貨點服務，沃爾瑪才有機會在今年打敗蘋果成為美國僅次於亞馬遜和eBay的第三大電子商務公司。
      </p>
      <img src={ img2 } alt="img2" />
      <p>
        當然沃爾瑪在電商之路的整合，美國當地的褒貶不一。在過去沃爾瑪也曾多次嘗試轉型，比如在時尚雜誌上投放廣告，為了就是改變形象提升超市的服務品質，但這些嘗試最終都以失敗收場，主要的原因也是由沃爾瑪在消費者心目中的形象太深刻。加拿大零售專家道格‧史蒂芬斯更說到:「說起沃爾瑪，人們的聯想是非常明確的。有些人要的就是沃爾瑪，有人壓根就不想跟它沾上邊。」隨著網路電商對線下零售市場的擴大，美國分析師認為沃爾瑪正擺脫當初問鼎五百強榜首的「平價策略」，但現在這個世代消費升級，服務也跟著升級，消費者也都在追尋更高質量的商品和服務，在這樣的趨勢下，沃爾瑪想擺脫低價的嘗試或許也是可行的辦法。在接下去沃爾瑪完成電商的布局後，不管是線上、線下的整合還是將所有的渠道打通後才是未來需要克服的難題。
      </p>
    </>
  )
}

export default Post24;