import React, { useState, useContext } from 'react';
import { Form, Input, Button, message } from 'antd';
import ContactusStyle from '../styles/pages/contactus.module.scss';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { config } from '../config/token';
import { LoadingContext } from '../components/_App';

const Contactus = () => {
  const loadingContext = useContext(LoadingContext);
  const [ recaptchaToken, setRecaptchaToken ] = useState('');
  const { t, i18n } = useTranslation();
  const validateMessages = {
    required: '此為必填欄位!',
    types: {
      email: '不是正確的信箱格式!'
    }
  };

  const onChange = value => {
    // console.log('Captcha value:', value);
    setRecaptchaToken(value);
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': `${ config.token }`
  }

  const [form] = Form.useForm();

  const onFinish = ( values ) => {
    values = { ...values, type: "contact" };
    // console.log(values);
    loadingContext.handleLoading();
    axios.post('/api/send', { 'data': values }, { headers: headers })
    .then(res => {
      if(res.status === 200){
        if(!recaptchaToken || recaptchaToken === undefined){
          recaptchaErrorMsg();
          loadingContext.handleNotLoading();
        } else {
          successMsg();
          loadingContext.handleNotLoading();
          onReset();
          window.grecaptcha.reset();
        }
      }
    })
    .catch(error => {
      console.log(error);
      // console.log(recaptchaToken);
      if(!recaptchaToken || recaptchaToken === undefined){
        recaptchaErrorMsg();
        loadingContext.handleNotLoading();
      } else {
        errorMsg();
        loadingContext.handleNotLoading();
        onReset();
        window.grecaptcha.reset();
      }
    })
  };

  const onReset = () => {
    form.resetFields();
  };

  const successMsg = () => {
    message.success('留言發送成功！')
    .then(() => window.location.reload());
  };

  const errorMsg = () => {
    message.error('留言發送失敗！')
    .then(() => window.location.reload());
  };

  const recaptchaErrorMsg = () => {
    message.error('請勾選 Recaptcha 核取方塊');
  }

  return(
    <>
      <MetaTags>
        <title>
          {  
            i18n.language === 'en' || i18n.language === 'jp' ?
            'Continental Global Service Ltd - One-Stop eCommerce Logistics Service' :
            'Continental Global Service Limited - 一站式跨境電商物流 | 聯絡我們'
          }
        </title>
      </MetaTags> 
      <div className={ ContactusStyle.contactus }>
        <div className="banner-color">
          <h1 className="wrap">{ t('page.contactus') }</h1>
        </div>
        <div className="wrap content">
          <h3 className={ ContactusStyle.title }>
            { t('contactus.text') }
          </h3>
          <div className={ ContactusStyle.form }>
            <Form name="contact" onFinish={ onFinish } validateMessages={ validateMessages } form={form}>
              <Form.Item label={ t('contactus.name') } name={['name']} rules={[{ required: true }]}>
                <Input size="large" />
              </Form.Item>

              <Form.Item label={ t('contactus.email') } name={['email']} rules={[{ type: 'email', required: true }]}>
                <Input size="large" />
              </Form.Item>

              <Form.Item label={ t('contactus.subject') } name={['subject']} rules={[{ required: true }]}>
                <Input size="large" />
              </Form.Item>

              <Form.Item label={ t('contactus.message') } name={['content']} rules={[{ required: true }]}>
                <Input.TextArea rows={4} />
              </Form.Item>

              <ReCAPTCHA
                sitekey={`${config.recaptcha_sitekey}`}
                onChange={ onChange }
              />

              <div className={ ContactusStyle.submitBtn }>
                <Button type="primary" htmlType="submit" loading={loadingContext.isLoading}>
                  { 
                    loadingContext.isLoading ? 
                    'Loading' :
                    t('contactus.send')
                  }
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contactus;