import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../img/Article15_img1.jpg';
import img2 from '../../img/Article15_img2.jpg';
import img3 from '../../img/Article15_img3.jpg';
import img4 from '../../img/Article15_img4.jpg';

const Post15 = () => {
  return(
    <>
      <p>
        若你已經擁有自營實體商店和網上商店，你必需為你的事業增添銷售渠道，使商品能夠被更多人發掘到。但並不是每個銷售平台都適合每間商店，你需要根據你的商店特質和你的目標客戶去選擇相符的平台。而且大多數平台都會根據你的商品數量或訂單收取對應的銷售佣金，所以你必須謹慎選擇網上商城。當然，在這些網站上展示您的產品的好處肯定不少，特別是如果您的目標是銷售大量產品或迎合特定的客戶群。這些網上市場，例如eBay、Etsy和Amazon，只需要基本的知識和營售策略，賣家和買家都能輕鬆完成買賣流程。以下為大家列出數個知名賣買平台：
      </p>
      <p>
        <span>Amazon</span><br />
        作為美國和歐洲的主要網上銷售平台，Amazon為B2B、B2C賣家提供大型供銷平台，在這裡集結了世界各地的買家，使您的企業有機會跳出當前的地理位置擴展到世界各地。Amazon每月有數百萬用戶在平台上購物，若跟隨Amazon的指南列出產品並遵守它們的細則，在Amazon的銷量和收入肯定會逐漸增加。而同時，Amazon在Google搜索投資了巨大金額，當搜索與商品貼近的關鍵字時會自動列出，使賣家在該平台上出售貨品有較大益處。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        <span>eBay</span><br />
        eBay主要供賣家出售二手或新品，是僅次於Amazon的電商平台。產品可以以固定價格出售，亦可以拍賣方式賣出。除非每月列出超過50件物品，否則不會收取上架費用，而是收取總銷售額的10％作為佣金。eBay重視賣家的利益，過往推出不同工具、功能和政策以增加賣家的體驗。eBay現時擁有1.7億活躍用家，雖然落後於Amazon，但這個數字相當於美國人口的一半以上，這個銷售潛力無限的平台將會為您帶來超乎想象的產品曝光率。
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        <span>Walmart</span><br />
        在發展電子商務前已成為全美最大的實體零售商，為了進攻電商業務投資了超過3億美元，足以看出Walmart對線上業務的看重。不過賣家若要在Walmart上架需要經過嚴格的審核，同時較Amazon和eBay慢，例如Walmart只接受有一定規模或聲譽的品牌商、年營業額至少30萬美金、並需在美國擁有自營倉庫或租用第三方倉庫以確保出貨速度。雖然Walmart進駐門檻較其他電商網站高，但可見能夠在其上架的貨品都有信心保證。
      </p>
      <img src={ img3 } alt="img3"/>
      <p>
        <span>Etsy</span><br />
        Etsy曾被稱為「祖母的地下室收藏」，不同於一般的電商平台，Etsy專注於手工藝產品，並且對工藝要求非常嚴格。雖說它針對的是小眾市場(niche market) ，市場佔有率亦相比其他公司細，但亦有大批忠實顧客。為了堅持真正的手工，Etsy只接受個人成為賣家，而代理則會被拒絕開店。在收費方面，Etsy價格上相對eBay更加實惠，每件手工藝品的上架費為0.2美元，隨後收取成交額的3.5％作為佣金。
      </p>
      <img src={ img4 } alt="img4"/>
      <p>
        先為4家知名網上銷售平台作簡單介紹，隨後會陸續為大家介紹其他平台。
      </p>
      <p>
        下篇請閱讀<Link to='/blog/16'>哪個跨境電商平台最合適？（下）</Link>
      </p>
    </>
  )
}

export default Post15;