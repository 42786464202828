import React from 'react';
import img1 from '../../img/Article17_img1.jpg';
import img2 from '../../img/Article17_img2.jpg';
import img3 from '../../img/Article17_img3.jpg';

const Post17 = () => {
  return(
    <>
      <p>
        “阿裡巴巴國際站改變我們的購物方式”。日前，阿裡巴巴國際站（<a href="http://alibaba.com/" target="_blank" rel="noreferrer">Alibaba.com</a>）亮相全美最具影響力的拉斯維加斯國際服裝服飾博覽會(MAGIC SHOW)，席間展示的多項數位化全新購物體驗功能，獲美國哥倫比亞廣播公司（CBS）等美媒大力稱讚。
      </p>
      <p>
        作為唯一參展的跨境B2B電商平臺，阿裡巴巴國際站此次運用新技術，創新實現邊看邊買、智慧試衣、智慧圖搜、即時翻譯等功能，吸引全美超過10000名專業買家駐足參觀。
      </p>
      <p>
        “去商店買衣服，拿一套試穿，出來發現搭配不合適，又得重試。”CBS電視臺主持人現場體驗阿裡巴巴國際站的智能試衣鏡時感慨，線下門店買衣服耗時繁瑣，如何搭配全靠YY。而智慧試衣鏡通過人工智慧和大資料技術，根據消費者的身型、膚色、臉型及穿衣喜好等，給予最精准的搭配建議。“太神奇了，阿裡巴巴國際站顛覆了我對買衣服的想像。”CBS主持人表示。
      </p>
      <img src={ img1 } alt="img1"/>
      <figcaption>
        現場參觀者在體驗阿裡巴巴國際站的智能試衣鏡
      </figcaption>
      <p>
        除了智慧試衣鏡，現場展示的“圖搜”功能也吸引大批美國買家青睞。“天哪，以後走在路上看到喜歡的衣服拍照就好了！”一位買家驚呼。
      </p>
      <img src={ img2 } alt="img2"/>
      <figcaption>
        Magic Show現場美國買家在體驗阿裡巴巴國際站的“圖搜”功能
      </figcaption>
      <p>
        “阿裡巴巴國際站的圖搜（Source Now）功能簡單易用，只需一鍵上傳照片，不出一秒便能立即找到產品的供應商”。美國零售行業權威分析機構Coresight Research撰文稱，阿裡巴巴國際站目前擁有超過1000萬活躍買家，覆蓋全球200多個國家和地區，通過互聯網打破傳統貿易邊界，為全球數千萬年輕人及中小企業提供了平等的貿易平臺。
      </p>
      <p>
        Coresight Research還介紹，阿裡巴巴國際站推出全新金融服務“Pay Later”，這是一款為小型買家提供“現在購買，以後付款”的資金借貸工具，可直接用於在國際站的定貨服務，符合條件的買家僅需數分鐘即可獲批，最高可借15萬美金。
      </p>
      <img src={ img3 } alt="img3"/>
      <figcaption>
        阿裡巴巴國際站在Magic Show上聯合中國供應商舉行T台走秀
      </figcaption>
      <p>
        此外，阿裡巴巴國際站帶著中國供應商一同參展，通過T台走秀、全球播放和邊看邊買等新穎形式，用線上化與數位化打破時間和地域邊界，讓全球數以百萬計的人均能通過線上參與和分享盛會。聯合參展的中國賣家收穫頗豐，現場收到的詢盤數量超出預期，部分賣家詢盤數出現成倍甚至十幾倍的增長。
      </p>
      <p>
        這不是國際站第一次與全球主流商業組織合作。今年1月，阿裡巴巴國際站首次與國際消費電子展(CES)達成官方合作，為展會新增5000平米的設計與採購館，吸引全球700多個供應商參與，其中超過40%的商家來自國際站。
      </p>
      <p>
        通過運用即時直播、AI即時語音翻譯等先進技術手段，國際站讓線上與線下貿易更好融合，拓寬了CES原有的邊界，讓商家不僅可以在CES相會，還能在現場獲得便利的貿易機會。
      </p>
      <p>
        “我們期待利用互聯網與大資料的先進技術，重構傳統貿易鏈路，説明更多致力於電子消費及高新科技行業的中小企業參與到‘全球買、全球賣’進程。”阿裡巴巴國際事業部聯席總經理張闊表示，重構傳統貿易鏈路將使各國中小企業從普惠、共贏的全球化貿易中受益。
      </p>
      <p>
        “阿裡巴巴是全球最受尊敬、成長迅速且業態十分豐富的公司。正因為其在全球化進程中的不斷努力，讓世界各地的企業與企業、企業和終端消費者的聯繫更加緊密。”CES掌門人蓋瑞·夏培羅（Gary Shapiro）對阿裡巴巴在全球貿易中的貢獻給予了肯定與讚揚。
      </p>
      <p style={{ textAlign: 'right' }}>
        （編輯：億恩網 賈銳傑）
      </p>
      <p>
        轉載至 <a href="https://www.ennews.com/article-9865-1.html" target="_blank" rel="noreferrer">http://www.ennews.com/article-9865-1.html</a>
      </p>
    </>
  )
}

export default Post17;