import React from 'react';
import img1 from '../../img/Article3_img1.jpg';

const Post3 = () => {
  return(
    <>
      <p>
        本公司十分榮幸能被在東南亞首屈一指的電商平台Shoppee 邀請，協助該公司舉辦在香港舉行的首場賣家沙龍。其實，國際知名電商平臺Amazon及Wish 當年在香港的首場簡介會，也是由本公司協助，足見我們在電商物流的豐富經驗被各大電商平台所認可。
      </p>
      <p>
        作為香港電商業界的一份子，推動業界朋友接觸海外平台，是我們的責任，與義務。事實上，東南亞與台灣擁有6億人口，網民高達3.6億，換句說話，超過一半居住在東南亞地區的市民都活躍於互聯網，成為目前全球經濟增長最快的地區之一，也成為近年最火熱的投資勝地。加上互聯網用戶平均每週上網超過16個小時，網購時間超過2個小時，這引發電子商務巨頭激烈競爭，故此香港賣家可透過知名電商平台銷售產品。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        在這次賣家沙龍中，該公司跨境業務總經理劉江宏先生，為各出席者解讀東南亞與台灣市場的最新動態，以及Shoppee的招商政策與營運策略，該公司的物流總監更耐心解答出席者的提問。
      </p>
      <p>
        會後劉總向我表示，香港賣家的質素非常高，其實，這也是香港電商能夠持續發展的基礎，也是我們的核心競爭力。我十分相信，憑藉在Shoppee於東南亞強大的銷售網絡，配合本公司優質的電商物流服務，一定會帶領更多香港電商走出香港開創新天地。
      </p>
    </>
  )
}

export default Post3;