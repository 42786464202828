import React from 'react';
import img1 from '../../img/Article32_img1.jpg';
import img2 from '../../img/Article32_img2.jpg';
import img3 from '../../img/Article32_img3.jpg';
import img4 from '../../img/Article32_img4.jpg';
import img5 from '../../img/Article32_img5.jpg';
import img6 from '../../img/Article32_img6.jpg';
import img7 from '../../img/Article32_img7.jpg';
import img8 from '../../img/Article32_img8.jpg';
import img9 from '../../img/Article32_img9.jpg';
import img10 from '../../img/Article32_img10.jpg';
import img11 from '../../img/Article32_img11.jpg';
import img12 from '../../img/Article32_img12.jpg';
import img13 from '../../img/Article32_img13.jpg';
import img14 from '../../img/Article32_img14.jpg';
import video1 from '../../img/Article32_video1.mp4';
import video2 from '../../img/Article32_video2.mp4';

const Post32 = () => {
  return(
    <>
      <p>
        現時美國以及英國的電子商務發展蓬勃，有電子商務平台修訂海外倉服務標準管理政策：前往英國站點的需求，在付款後5個工作日內不低於50%的送達掃描率；美國站點的需求, 在付款後12個工作日內不低於50%的送達掃描率。為了更有效接收客戶的貨件，我們在當地設立的自營倉庫，提升海外貨物的吞吐量。
      </p>
      <p>
        我們在全球擁有17個點，覆蓋多個電商主要市場，無論直線發貨或倉庫直寄都能滿足客戶需求。如對我們的服務有興趣，歡迎以電郵 (<a href="mailto:sales@contin-global.com">sales@contin-global.com</a>) 或填寫網頁底的表格與我們查詢。
      </p>
      <p>
        美國東倉庫：
      </p>
      <img src={ img1 } alt="img1" />
      <img src={ img2 } alt="img2" />
      <img src={ img3 } alt="img3" />
      <img src={ img4 } alt="img4" />
      <img src={ img5 } alt="img5" />
      <img src={ img6 } alt="img6" />
      <div style={{ paddingBottom: '56.25%', position: 'relative', overflow: 'hidden', height: '0' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute', top: '0', left: '0' }} data-loaded="true">
          <video src={ video1 } preload="auto" controls style={{ width: '100%', height: '100%' }}></video>
        </div>
      </div>
      <p>
        英國倉庫：
      </p>
      <img src={ img7 } alt="img7" />
      <img src={ img8 } alt="img8" />
      <img src={ img9 } alt="img9" />
      <img src={ img10 } alt="img10" />
      <img src={ img11 } alt="img11" />
      <img src={ img12 } alt="img12" />
      <img src={ img13 } alt="img13" />
      <img src={ img14 } alt="img14" />
      <div style={{ paddingBottom: '56.25%', position: 'relative', overflow: 'hidden', height: '0' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute', top: '0', left: '0' }} data-loaded="true">
          <video src={ video2 } preload="auto" controls style={{ width: '100%', height: '100%' }}></video>
        </div>
      </div>
    </>
  )
}

export default Post32;