import React from 'react';
import img1 from '../../img/Article25_img1.jpg';
import img2 from '../../img/Article25_img2.jpg';

const Post25 = () => {
  return(
    <>
      <img src={ img1 } alt="img1" />
      <p>
        相對於中國阿里巴巴的雙11購物節，北美地區較盛行的自然是每年11月第四個星期五，俗稱的黑色星期五(Black Friday)的購物節活動，在這天不管是實體通路或網路銷售平台都會推出一系列促銷活動吸引消費者上門購物，藉此來響應即將到來的聖誕節。那麼黑色星期五是怎麼來的呢? 黑色星期五名稱的由來有不一樣的說法，其中之一是由於這一天為感恩節(11月第四個星期四)後開業的第一天。再加上大家會開始聖誕節大採購。傳統的商家會用不同顏色的墨水來紀錄整年度的虧損，然而紅色代表虧損，黑色則代表盈利。店家之所以會把這天叫做”黑色星期五”是期待這一天過後，年度營收會由負轉成正，由紅字轉為黑字。員工則用這一天來自嘲，表示這一天會忙到不行。
      </p>
      <p>
        在今年，美國經濟一路看好，失業率也處於數十年來低點，10月份消費者信心指數更創下18年來的新高。奧比多分析公司(Adobe Analytics)數據顯示，截至美東時間23日上午10時，黑色星期五線上銷售額較去年同期增長23.6%，達62.2億美元。就連感恩節的線上銷售額更達破37億美元。另外通過智慧型手機的線上銷售也創下新的紀錄。在周六的新數據顯示，在今年購物季節，更多消費者向網購，從服裝到平板電腦，幾乎涵蓋了所有類別的商品。
      </p>
      <p>
        奧比多分析公司表示，今年消費者利用智慧型手機購物，金額超過20億美元，是史上首次。而今年黑色星期五當天電子商務銷售額的33.5%移動設備，而去年2017年則為29.1%。感恩節及黑色星期五實體店銷售額減少4至7%，客流量下降5至9%，延續去年下降趨勢。另一分析公司ShopperTrak的數據亦顯示，該兩日實體店面客流量下降約1%。雖然目前還無法準確解釋手機購物上升的現象起因為何，但當前的商家多數都提供了更好「行動購物體驗」。然而，手機螢幕的尺寸提升，相信這也是讓消費者在手機上購物更加順手的因素之一。不論你是否為黑色星期五所顯示出的消費主義，根據數據，人們在線上大型採買時，越來越傾向使用手機而不是電腦。
      </p>
      <img src={ img2 } alt="img2" />
      <p>
        在黑色星期五之後，美國各地購物熱潮會一路延續到26日「網購星期一」(Cyber Monday)。對於新一代年輕人，網購星期一才是購物節的開始。加州葛瑞安德森經濟研究中心主任斯費（Raymond Sfeir）指出，今年電子商務將在整體零售額上占有更大比例，許多商家收掉了實體店面與購物中心，由於現在年輕人購物習慣拿出手機，點個幾下，之後準備收包裹。儘管上網購物方便許多，但未經試穿就買下的衣服，很多會被退貨。可能買了5件只留下1件，其它都寄回退貨。這就是年輕人買東西的方式，他們不習慣到店裡看一看或試穿。」所以網絡星期一的銷售額與感恩節假期及黑色星期五比起來，增長速度會更快。
      </p>
    </>
  )
}

export default Post25;