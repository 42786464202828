import React, { useState, useEffect, createContext } from 'react';
import 'antd/dist/antd.css';
import '../styles/components/common.scss';
import { Modal, Button, Spin } from 'antd';
import { BrowserRouter as Router, Route, useLocation, withRouter, Link } from 'react-router-dom';
import { RouteConfig } from '../config/route';
import Topbar from '../components/Topbar';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import Footer from '../components/Footer';

const HandleScrollToTop = (props) => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ pathname ]);

  return props.children;
}

const ScrollToTop = withRouter(HandleScrollToTop);

export const LoadingContext = createContext();

const App = () => {
  const [ showModal, setShowModal ] = useState(false);

  // 取得視窗寬度 hooks
  const useWindowWidth = () => {
    const [ windowWidth, setWindowWidth ] = useState(undefined);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth)
      }

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth
  };

  const windowSize = useWindowWidth();

  useEffect(() => {
    setShowModal(false);
  }, [])

  const handleCancel = () => {
    setShowModal(false);
  }

  // loading controll
  const [ isLoading, setIsLoading ] = useState(false);
  const handleLoading = () => {
    setIsLoading(true);
  }

  const handleNotLoading = () => {
    setIsLoading(false);
  }

  return (
    <>
      <LoadingContext.Provider value={{ isLoading, handleLoading, handleNotLoading }}>
        <Router>
          <Spin tip="Loading..." spinning={ isLoading }>
            <Modal
              visible={ showModal }
              title="最新消息"
              width={ windowSize > 768 ? 780 : '90%' }
              style={ windowSize <= 414 ? { top: 80 } : { top: 150 }}
              onCancel={ handleCancel }
              footer={[
                <Button type="primary" onClick={ handleCancel }>
                  <Link to="/remote">了解更多</Link>
                </Button>,
                <Button type="primary" onClick={ handleCancel }>
                  <Link to="/contactus">聯絡我們</Link>
                </Button>,
              ]}
            >
              <h3>政府D-Biz「遙距營商計劃」<br />即將出爐！</h3>
              <p>以資助企業利用創新與科技，在疫情期間開拓遙距業務，<br />適合香港中小企業申請！</p>
            </Modal>
            <ScrollToTop>
              { windowSize > 768 ? <Topbar /> : null }
              { windowSize > 768 ? <Navbar /> : <NavbarMobile /> }
              {
                RouteConfig.map(( item, index ) => (
                  <Route 
                    key={ index }
                    path={ item.path }
                    exact={ item.exact }
                    component={ item.component }
                  /> 
                ))
              }
              <Footer />
            </ScrollToTop>
          </Spin>
        </Router>
      </LoadingContext.Provider>
    </>
  );
}

export default App;