import React from 'react';
import RemoteStyle from '../styles/pages/remote.module.scss';
import Remote_img_01 from '../img/Remote_img_01.png';
import Remote_img_02 from '../img/Remote_img_02.png';
import Remote_img_03 from '../img/Remote_img_03.png';
import Remote_img_04 from '../img/Remote_img_04.png';
import Remote_img_05 from '../img/Remote_img_05.png';
import Remote_img_06 from '../img/Remote_img_06.png';
import Remote_img_07 from '../img/Remote_img_07.png';
import MetaTags from 'react-meta-tags';

const Remote = () => {
  return(
    <>
      <MetaTags>
        <title>
          Continental Global Service Limited - 一站式跨境電商物流 | 遙距營商計劃服務
        </title>
      </MetaTags>
      <div className={ RemoteStyle.remote }>
        <div className="banner-color">
          <h1 className="wrap">遙距營商計劃服務</h1>
        </div>
        <div className="wrap content">
          <div className={ RemoteStyle.content }>
            <p>
              Continental Global Service Limited 致力為客戶提供安全穩定的跨境物流以至電子商務營運一條龍服務，協助企業數碼轉型，開拓網上業務，為品牌及產品創造新的價値！過去成立至今為不少企業提供網上接單至送遞服務以及智能自助物流系統，再配搭網上營商服務和數碼推廣策略，使香港的客戶業務也能迅速擴展至全球市場。
            </p>
            <p>
              藉着政府 D-Biz「遙距營商計劃」的推出，憑我們的專業和經驗相信是不二之選，助你善用資助把效益最大化，以下是我們為計劃提供的針對性服務：
            </p>
            <div className={ RemoteStyle.tableWrapper }>
              <table className={ RemoteStyle.table }>
                <tbody>
                  <tr>
                    <td rowSpan={2}></td>
                    <td colSpan={2} className={ RemoteStyle.heightSmall }>
                      <span>網上營商 (Online Business)</span>
                    </td>
                    <td rowSpan={2}>
                      <span>智能自助<br />物流服務系統</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>開發及管理<br />電商平台<br />(Online Stores)</span>
                    </td>
                    <td>
                      <span>數碼廣告推廣(Digital Advertisement)</span>
                    </td>
                  </tr>
                  <tr>
                    <td>香港網上商店<br />銷售於<span>本地市場</span></td>
                    <td>
                      創立及管理本地銷售<br />平台帳戶
                      <img src={ Remote_img_01 } alt="Remote_img_01" />
                    </td>
                    <td rowSpan={2}>第三方銷售平台<br />營銷推廣<br /><br />
                    <ul>
                      <li>• 站內曝光</li>
                      <li>• 站外引流</li>
                    </ul>
                    </td>
                    <td rowSpan={3} className={ RemoteStyle.alignLeft }>連接電商平台網上接單和送遞產品<br /><br />點對點訂單交付服務<br /><br />海外倉庫倉存、包裝及發貨<br /><br />售後客服及當地退件服務</td>
                  </tr>
                  <tr>
                    <td>香港網上商店<br />銷售於<span>歐美電商市場</span></td>
                    <td>
                      創立及管理<span>跨境銷售平台</span>帳戶
                      <img src={ Remote_img_02 } alt="Remote_img_02" />
                      <img src={ Remote_img_03 } alt="Remote_img_03" />
                    </td>
                  </tr>
                  <tr>
                    <td>香港<span>網上商店<br />(度身訂造電商平台)</span></td>
                    <td>
                      創立客戶<span>個人網站</span>，建立<span>支付系統</span>
                      <img src={ Remote_img_04 } alt="Remote_img_04" />
                      <img src={ Remote_img_05 } alt="Remote_img_05" />
                      <img src={ Remote_img_06 } alt="Remote_img_06" />
                    </td>
                    <td>搜尋引擎營銷SEM</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={ RemoteStyle.customer }>
              <h3>合作客戶</h3>
              <a href="https://brando.com/" target="_blank" rel="noreferrer">
                <img src={ Remote_img_07 } alt="Remote_img_07" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Remote