import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zh from '../lang/zh.json';
import en from '../lang/en.json';
import jp from '../lang/jp.json';

const resources = {
  'zh-TW': {
    translation: zh,
  },
  'en': {
    translation: en,
  },
  'jp': {
    translation: jp,
  },
 };
 
 i18n.use(initReactI18next).init({
   resources,
   lng: 'zh-TW',             
   fallbackLng: 'zh-TW',
   interpolation: {
     escapeValue: false,
   },
 });

export default i18n;