import React from 'react';
import AboutusStyle from '../styles/pages/aboutus.module.scss';
import Aboutus_img_1 from '../img/Aboutus_img_1.jpg';
import Aboutus_img_2 from '../img/Aboutus_img_2.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import Fedex from  '../img/Fedex.png';
import dpd from '../img/dpd.png';
import Royal_mail from '../img/Royal_mail.png';
import Fastway from '../img/Fastway.png';
import Ups from '../img/Ups.png';
import TNT from '../img/TNT.png';
import Correos from '../img/Correos.png';
import Colissimo from '../img/Colissimo.png';
import DeutschePost from '../img/DeutschePost.png';
import Hermes from '../img/Hermes.png';
import AustraliaPost from '../img/AustraliaPost.png';
import USPS from '../img/USPS.png';
import ebay from  '../img/ebay.png';
import amazon from '../img/amazon.png';
import KickStarter from '../img/KickStarter.png';
import Indiegogo from '../img/Indiegogo.png';
import Cdiscount from '../img/Cdiscount.png';
import wish from '../img/wish.png';
import Aliexpress from '../img/Aliexpress.png';
import Walmart from '../img/Walmart.png';
import newegg from '../img/newegg.png';
import Rakuten from '../img/Rakuten.png';
import PriceMinister from '../img/PriceMinister.png';
import MetaTags from 'react-meta-tags';

const Aboutus = () => {
  const { t, i18n } = useTranslation();
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return(
    <>
      <MetaTags>
        <title>
          {  
            i18n.language === 'en' || i18n.language === 'jp' ?
            'Continental Global Service Ltd - One-Stop eCommerce Logistics Service' :
            'Continental Global Service Limited - 一站式跨境電商物流 | 關於我們'
          }
        </title>
      </MetaTags>
      <div className={ AboutusStyle.aboutus }>
        <div className="banner-color">
          <h1 className="wrap">{ t('page.aboutus') }</h1>
        </div>
        <div className="wrap content">
          <div className={ AboutusStyle.content }>
            <div className={ AboutusStyle.img }>
              <img src={ Aboutus_img_1 } alt="Aboutus_img_1"/>
            </div>
            <div className={ AboutusStyle.text }>
              <section>
                { t('aboutus.text.1') }
              </section>
              <section>
                { t('aboutus.text.2') }
                {
                  i18n.language === 'jp' ?
                  null :
                  <ul>
                    <li>{ t('aboutus.text.2.list.1') }</li>
                    <li>{ t('aboutus.text.2.list.2') }</li>
                    <li>{ t('aboutus.text.2.list.3') }</li>
                  </ul>
                }
              </section>
              <section>
                { t('aboutus.text.3') }
                {
                  i18n.language === 'jp' ?
                  null :
                  <ul>
                    <li>{ t('aboutus.text.3.list.1') }</li>
                    <li>{ t('aboutus.text.3.list.2') }</li>
                    <li>{ t('aboutus.text.3.list.3') }</li>
                    <li>{ t('aboutus.text.3.list.4') }</li>
                  </ul>
                }
              </section>
            </div>
          </div>
          <div className={ AboutusStyle.partners }>
            <div className="wrap">
              <Slider {...settings}>
                <div>
                  <h3>{ t('aboutus.logo.title.1') }</h3>
                  <div className={ AboutusStyle.logo }>
                    <img src={ Fedex } alt="Fedex" />
                    <img src={ dpd } alt="dpd" />
                    <img src={ Royal_mail } alt="Royal_mail" />
                    <img src={ Fastway } alt="Fastway" />
                    <img src={ Ups } alt="Ups" />
                    <img src={ TNT } alt="TNT" />
                    <img src={ Correos } alt="Correos" />
                    <img src={ Colissimo } alt="Colissimo" />
                    <img src={ DeutschePost } alt="DeutschePost" />
                    <img src={ Hermes } alt="Hermes" />
                    <img src={ AustraliaPost } alt="AustraliaPost" />
                    <img src={ USPS } alt="USPS" />
                  </div>
                </div>
                <div>
                  <h3>{ t('aboutus.logo.title.2') }</h3>
                  <div className={ AboutusStyle.logo }>
                    <img src={ ebay } alt="ebay" />
                    <img src={ amazon } alt="amazon" />
                    <img src={ KickStarter } alt="KickStarter" />
                    <img src={ Indiegogo } alt="Indiegogo" />
                    <img src={ Cdiscount } alt="Cdiscount" />
                    <img src={ wish } alt="wish" />
                    <img src={ Aliexpress } alt="Aliexpress" />
                    <img src={ Walmart } alt="Walmart" />
                    <img src={ newegg } alt="newegg" />
                    <img src={ Rakuten } alt="Rakuten" />
                    <img src={ PriceMinister } alt="PriceMinister" />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className={ AboutusStyle.content }>
          <div className={ AboutusStyle.img }>
            <img src={ Aboutus_img_2 } alt="Aboutus_img_2"/>
          </div>
          <div className={ AboutusStyle.text }>
            {
              i18n.language === 'en' || i18n.language === 'jp' ?
              null : 
              <section>
                Continental Global Service Limited 致力為客戶力求完美，至今已獲得多個獎項。
              </section>
            }
            <section>
              <span>{ t('aboutus.2016') }</span>
              <ul>
                <li>{ t('aboutus.2016.list.1') }</li>
                <li>{ t('aboutus.2016.list.2') }</li>
                {
                  i18n.language === 'en' ?
                  null :
                  <li>{ t('aboutus.2016.list.3') }</li>
                }
                <li>{ t('aboutus.2016.list.4') }</li>
              </ul>
            </section>
            <section>
              <span>{ t('aboutus.2015') }</span>
              <ul>
                <li>{ t('aboutus.2015.list.1') }</li>
              </ul>
            </section>
            <section>
              <span>{ t('aboutus.2011') }</span>
              <ul>
                <li>{ t('aboutus.2011.list.1') }</li>
              </ul>
            </section>
            <section>
              <span>{ t('aboutus.2010') }</span>
              <ul>
                <li>{ t('aboutus.2010.list.1') }</li>
              </ul>
            </section>
            <section>
              <span>{ t('aboutus.2009') }</span>
              <ul>
                <li>{ t('aboutus.2009.list.1') }</li>
              </ul>
            </section>
            <section>
              <span>{ t('aboutus.2008') }</span>
              <ul>
                <li>{ t('aboutus.2008.list.1') }</li>
              </ul>
            </section>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default Aboutus;