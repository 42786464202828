import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Avatar, Row, Col, Card } from 'antd';
import BlogPostsStyle from '../styles/pages/blogPosts.module.scss';
import blogList from '../config/blogList';
import AvatarImg from '../img/Avatar.jpg';
import Post1 from './post/1';
import Post2 from './post/2';
import Post3 from './post/3';
import Post4 from './post/4';
import Post5 from './post/5';
import Post6 from './post/6';
import Post7 from './post/7';
import Post8 from './post/8';
import Post9 from './post/9';
import Post10 from './post/10';
import Post11 from './post/11';
import Post12 from './post/12';
import Post13 from './post/13';
import Post14 from './post/14';
import Post15 from './post/15';
import Post16 from './post/16';
import Post17 from './post/17';
import Post18 from './post/18';
import Post19 from './post/19';
import Post20 from './post/20';
import Post21 from './post/21';
import Post22 from './post/22';
import Post23 from './post/23';
import Post24 from './post/24';
import Post25 from './post/25';
import Post26 from './post/26';
import Post27 from './post/27';
import Post28 from './post/28';
import Post29 from './post/29';
import Post30 from './post/30';
import Post31 from './post/31';
import Post32 from './post/32';
import Post33 from './post/33';
import MetaTags from 'react-meta-tags';

const { Meta } = Card;

const postData = [
  <Post1 />,
  <Post2 />,
  <Post3 />,
  <Post4 />,
  <Post5 />,
  <Post6 />,
  <Post7 />,
  <Post8 />,
  <Post9 />,
  <Post10 />,
  <Post11 />,
  <Post12 />,
  <Post13 />,
  <Post14 />,
  <Post15 />,
  <Post16 />,
  <Post17 />,
  <Post18 />,
  <Post19 />,
  <Post20 />,
  <Post21 />,
  <Post22 />,
  <Post23 />,
  <Post24 />,
  <Post25 />,
  <Post26 />,
  <Post27 />,
  <Post28 />,
  <Post29 />,
  <Post30 />,
  <Post31 />,
  <Post32 />,
  <Post33 />,
];

const BlogPosts = () => {
  let { postId } = useParams();
  console.log(postId);
  const [ newPosts, setNewPosts ] = useState([]);

  useEffect(()=> {
    handleNewPosts();
  }, [postId]);

  const handleNewPosts = () =>{
    if(postId === '33'){
      let data = [
        {
          title: blogList[31].title,
          image: blogList[31].image.default,
          link: blogList[31].key
        },
        {
          title: blogList[30].title,
          image: blogList[30].image.default,
          link: blogList[30].key
        },
        {
          title: blogList[29].title,
          image: blogList[29].image.default,
          link: blogList[29].key
        },
      ];
      setNewPosts(data);
    } else if(postId === '32'){
      let data = [
        {
          title: blogList[30].title,
          image: blogList[30].image.default,
          link: blogList[30].key
        },
        {
          title: blogList[29].title,
          image: blogList[29].image.default,
          link: blogList[29].key
        },
        {
          title: blogList[28].title,
          image: blogList[28].image.default,
          link: blogList[28].key
        },
      ];
      setNewPosts(data);
    } else if(postId === '31'){
      let data = [
        {
          title: blogList[29].title,
          image: blogList[29].image.default,
          link: blogList[29].key
        },
        {
          title: blogList[28].title,
          image: blogList[28].image.default,
          link: blogList[28].key
        },
        {
          title: blogList[27].title,
          image: blogList[27].image.default,
          link: blogList[27].key
        },
      ];
      setNewPosts(data);
    }
    else {
      let data = [
        {
          title: blogList[32].title,
          image: blogList[32].image.default,
          link: blogList[32].key
        },
        {
          title: blogList[31].title,
          image: blogList[31].image.default,
          link: blogList[31].key
        },
        {
          title: blogList[30].title,
          image: blogList[30].image.default,
          link: blogList[30].key
        },
      ];
      setNewPosts(data);
    }
  }

  return(
    <>
      <MetaTags>
        <title>
          { blogList[postId - 1].title }
        </title>
      </MetaTags>
      <div className={`blogPosts ${BlogPostsStyle.blogPosts}`}>
        <div className="wrap content">
          <div className={ BlogPostsStyle.content }>
            <div className={ BlogPostsStyle.author }>
              <Avatar src={ AvatarImg } />
              <span className={ BlogPostsStyle.name }>Stanley Lee</span>ㆍ
              <span className={ BlogPostsStyle.time }>
                { blogList[postId - 1].time }
              </span>
            </div>
            <div className={ BlogPostsStyle.title }>
              <h1>{ blogList[postId - 1].title }</h1>
            </div>
            <div className={ BlogPostsStyle.articleMain }>
              { postData[postId - 1] }
            </div>
          </div>
          <div className={ BlogPostsStyle.newPosts }>
            <h3>最新文章<Link to="/blog">查看全部</Link></h3>
            <Row gutter={24}>
              {
                newPosts.map((item, index) => (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
                    <Card 
                      style={{ height: 400 }}
                      className={ BlogPostsStyle.newPostItem }
                      cover={
                        <Link to={`/blog/${item.link}`}>
                          <img src={ item.image } alt={ item.title } />
                        </Link>
                      }
                    >
                      <Meta 
                        title={
                          <Link to={`/blog/${item.link}`}>
                            <h2>{item.title}</h2>
                          </Link>
                        } 
                      />
                    </Card>
                  </Col>
                ))
              }
            </Row>   
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogPosts