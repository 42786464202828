import React from 'react';
import img1 from '../../img/Article28_img1.jpg';
import img2 from '../../img/Article28_img2.jpg';
import img3 from '../../img/Article28_img3.jpg';
import img4 from '../../img/Article28_img4.jpg';
import img5 from '../../img/Article28_img5.jpg';

const Post28 = () => {
  return(
    <>
      <img src={ img1 } alt="img1" />
      <p>
        馬雲曾說過：「不做電子商務，五年後你會後悔。十年後，你要是在不做電子商務的話，那麼你將無商可務。」根據eMarketer調查網站指出預估2020年全球將有21.14億人口使用網路購物，然而將有超過9億的人口使用跨境購物，幾乎是總網購人口的一半。
      </p>
      <img src={ img2 } alt="img2" />
      <p>
        根據2017年資策會產業情報研究所(MIC)指出台灣跨境網購主要消費族群落在26 ~ 35歲，且每年平均消費金額為16,378元。那至於海外網站購物三大誘因分別為價格實惠(69%)、種類多元(42.2)、折扣多或促銷頻繁(29%)。以上總總原因促成集團中台灣Buyippee買+易和台灣樂天市場首次合作講座案。
      </p>
      <img src={ img3 } alt="img3" />
      <p>
        這次講座很榮幸和台灣樂天市場首次合作，其中我們邀請了約80位賣家來參與，在講座的前半部主要分享除了工作之餘如何幫自己加薪以及代購賣家養成計畫，更重要的是Buyippee更推出出席講座便享有5公斤跨國運費免費試用體驗!
      </p>
      <img src={ img4 } alt="img4" />
      <p>
        講座上半場的內容主要分成四個部分分別為全球跨境電商趨勢分析、分享代購賣家經營手法、傳授海外商品選務秘笈以及輕鬆了解進口關稅運費；下半場的內容則著重於樂天全球及在地化經營策略、全球四大電商平台模式解析以及百萬店鋪必備三大電商規劃。由於此次為台灣Buyippee首次講座，在會後，有許多感興趣的賣家和從來沒有使用過代運代購服務的朋友對於不同地區的服務費、運費、關稅….等等都想有更進一步的了解和諮詢，Buyippee團隊也一一為回答所有問題。藉由這次的講座也讓更多人有機會接觸Buyippee的服務，也是台灣團隊跨出的一大步。
      </p>
      <img src={ img5 } alt="img5" />
      <p>
        Buyippee團隊是你們最專業的顧問，我們提供代購代運雙服務，在業界擁有最多海外地址，並且港台團隊同步經營，不必煩惱進口清關手續!<br />
        <a href="https://www.buyippee.com.tw/" target="_blank" rel="noreferrer">www.buyippee.com.tw</a>
      </p>
    </>
  )
}

export default Post28;