import React from 'react';
import LogisticStyle from '../styles/pages/logistic.module.scss';
import Logistic_img_1 from '../img/Logistic_img_1.jpg';
import Logistic_img_2 from '../img/Logistic_img_2.jpg';
import DHL from '../img/DHL.png';
import Fedex from '../img/Fedex.png';
import dpd from '../img/dpd.png';
import Fastway from '../img/Fastway.png';
import Royal_mail from '../img/Royal_mail.png';
import Ups from '../img/Ups.png';
import TNT from '../img/TNT.png';
import Correos from '../img/Correos.png';
import Colissimo from '../img/Colissimo.png';
import DeutschePost from '../img/DeutschePost.png';
import Hermes from '../img/Hermes.png';
import AustraliaPost from '../img/AustraliaPost.png';
import USPS from '../img/USPS.png';
import MetaTags from 'react-meta-tags';

const Logistic = () => {
  return(
    <>
      <MetaTags>
        <title>
          Continental Global Service Limited - 一站式跨境電商物流 | 專線物流
        </title>
      </MetaTags>
      <div className={ LogisticStyle.logistic }>
        <div className="banner-color">
          <h1 className="wrap">專線物流</h1>
        </div>
        <div className="wrap content">
          <h4 className={ LogisticStyle.intro }>
            我們擁有專業的物流配送服務，上門接收賣家貨件，節省運送時間，為賣家提供更優質的物流體驗。
          </h4>
          <div className={ LogisticStyle.imgGroup }>
            <img src={ Logistic_img_1 } alt="Logistic_img_1" />
            <img src={ Logistic_img_2 } alt="Logistic_img_2" />
          </div>
          <h4 className={ LogisticStyle.intro }>
            與多個知名物流公司保持良好的合作關係，無論在國際運輸抑或當地派送都能為賣家提供最優質的服務。 
          </h4>
          <div className={ LogisticStyle.logo }>
            <img src={ DHL } alt="DHL" />
            <img src={ Fedex } alt="Fedex" />
            <img src={ dpd } alt="dpd" />
            <img src={ Fastway } alt="Fastway" />
            <img src={ Royal_mail } alt="Royal_mail" />
            <img src={ Ups } alt="Ups" />
            <img src={ TNT } alt="TNT" />
            <img src={ Correos } alt="Correos" />
            <img src={ Colissimo } alt="Colissimo" />
            <img src={ DeutschePost } alt="DeutschePost" />
            <img src={ Hermes } alt="Hermes" />
            <img src={ AustraliaPost } alt="AustraliaPost" />
            <img src={ USPS } alt="USPS" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Logistic