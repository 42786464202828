import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation, Translation } from 'react-i18next';

const { SubMenu } = Menu;

const MenuComp = (props) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  return(
    <Menu 
      mode={ props.mode } 
      style={ 
        props.mode === 'horizontal' ? 
        { width: '73%', justifyContent: 'flex-end' }:
        { width: '100%'}
      }
    >
      <Menu.Item key="home" onClick={ props.onClick }>
        <Link 
          to="/"
          className={ location.pathname === "/"? "active" : null }
        >
          { t('page.home') }
        </Link>
      </Menu.Item>
      <Menu.Item key="aboutus" onClick={ props.onClick }>
        <Link 
          to="/aboutus"
          className={ location.pathname === "/aboutus"? "active" : null }
        >
          { t('page.aboutus') }
        </Link>
      </Menu.Item>
      {
        i18n.language === "en" || i18n.language === "jp" ?
        null :
        <Menu.Item key="remote" onClick={ props.onClick }>
          <Link 
            to="/remote"
            className={ location.pathname === "/remote"? "active" : null }
          >
            遙距營商計劃服務
          </Link>
        </Menu.Item>
      }
      {
        i18n.language === "en" || i18n.language === "jp" ?
        null :
        <Menu.Item key="blog" onClick={ props.onClick }>
          <Link 
            to="/blog"
            className={ location.pathname === "/blog"? "active" : null }
          >
            電商日誌
          </Link>
        </Menu.Item>
      }
      {
        i18n.language === "en" || i18n.language === "jp" ?
        <>
          <Menu.Item key="service" onClick={ props.onClick }>
            <Link 
              to="/service"
              className={ location.pathname === "/service"? "active" : null }
            >
              { t('page.service') }
            </Link>
          </Menu.Item>
          <Menu.Item key="location" onClick={ props.onClick }>
            <Link 
              to="/location"
              className={ location.pathname === "/location"? "active" : null }
            >
              { t('page.location') }
            </Link>
          </Menu.Item>            
          <Menu.Item key="faq" onClick={ props.onClick }>
            <Link 
              to="/faq"
              className={ location.pathname === "/faq"? "active" : null }
            >
              { t('page.faq') }
            </Link>
          </Menu.Item>
        </>
        :
        <SubMenu 
          key="SubMenu" 
          title={
            <Translation>{ t => <>{ t('page.serviceTitle') }</> }</Translation>
          }
          className={ location.pathname === "/service" || location.pathname === "/location" || location.pathname === "/faq" || location.pathname === "/logistic" ? "active" : null }
        >
          <Menu.Item key="service" onClick={ props.onClick }>
            <Link 
              to="/service"
              className={ location.pathname === "/service"? "active" : null }
            >
              { t('page.service') }
            </Link>
          </Menu.Item>
          <Menu.Item key="location" onClick={ props.onClick }>
            <Link 
              to="/location"
              className={ location.pathname === "/location"? "active" : null }
            >
              { t('page.location') }
            </Link>
          </Menu.Item>            
          <Menu.Item key="faq" onClick={ props.onClick }>
            <Link 
              to="/faq"
              className={ location.pathname === "/faq"? "active" : null }
            >
              { t('page.faq') }
            </Link>
          </Menu.Item>
          <Menu.Item key="logistic" onClick={ props.onClick }>
            <Link 
              to="/logistic"
              className={ location.pathname === "/logistic"? "active" : null }
            >
              專線物流
            </Link>
          </Menu.Item>   
        </SubMenu>
      }
      
      <Menu.Item key="contactus" onClick={ props.onClick }>
        <Link 
          to="/contactus"
          className={ location.pathname === "/contactus"? "active" : null }
        >
          { t('page.contactus') }
        </Link>
      </Menu.Item>
    </Menu>
  );
}

export default MenuComp;