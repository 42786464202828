import React from 'react';
import img1 from '../../img/Article8_img1.jpg';

const Post8 = () => {
  return(
    <>
      <p>
        距離感恩節還有三個多月，電商賣家雖則要為貿易旺季做好準備，但亦不能只看長線，不為淡季作任何打算。其實在夏季要推動Amazon產品銷量很簡單，你需要以下這幾個方法：
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        <span>1. 增加FBA倉儲</span><br />
        FBA賣家常用術語 “Feed the Beast” 意思是要不斷將庫存送到Amazon。但為何銷售一般，仍要不斷輸入貨品？試想像FBA就像一頭野獸，它不但餵不飽而且很擇食，給予它不同種類食物，始終有些會符合它喜好並消化掉。有時商品銷量低的原因歸究於商品種類較少，往往錯失了接觸買家的最佳時機。因此，你需要洞息市場的潮流，了解買家的需要，為你Amazon店鋪添加商品。
      </p>
      <p>
        <span>2. 銷售多樣性產品</span><br />
        過往可能你想銷售的產品屬於受限品類，未能登上在Amazon平台。然而最近Amazon開放了不同以往受限的品類，包括食用品、鞋類、珠寶、保健與美容用品等等，當然一些獨立品牌產品可能仍然受限。現時Amazon已有超過30種類別可供賣家銷售，不妨到Amazon檢查一下是否有你想售賣的貨品。不要再專注於銷售單類產品，試著添加不同種類到Amazon商店。
      </p>
      <p>
        <span>3. 參與Prime Day</span><br />
        第四屆為期36小時的Amazon Prime Day將於2018年7月16日美國時間下午3時舉行，直至7月18日早上3時結束。Prime Day在2015初次舉行，每年都取得巨大銷售額。據Amazon的官方統計，2016年的Prime Day平均每秒售出398件貨品。而且Amazon Prime Day的折扣相比感恩節低，表示賣家的利潤絕不會低於感恩節。跨境賣家不妨今天起做好準備，為迎接幾天後的Prime Day。
      </p>
      <p>
        <span>4. 定期更新定價</span><br />
        大多賣家只會在上架時為商品定價，後續不會去更新價格。若果你的商品在一個月內銷售額較為慘淡，很有可能你的定價不再具有競爭力。因此賣家可檢查一下現時商品價格是否與市場定價差太遠，若價格遠低於原本的定價，可考慮投放更多資源在其他銷售更好的商品上。
      </p>
      <p>
        <span>5. 捆綁式銷售</span><br />
        在Amazon設定捆綁式銷售能讓你的商品在平台上更為突出。原理是將不同商品捆綁在一起，形成一個新的商品。設計良好的捆綁商品可以為買家帶來便利和價值。當你成為唯一一個賣家銷商該捆綁式商品，即表示你能登上於黃金購買車。而其他商家渴望分一杯羹時，他們必須添上與你相同的商品，並把價格壓得比你低。
      </p>
      <p>
        <span>6. 采購季節性產品</span><br />
        8月份是返校旺季將來臨，Amazon早已推出了 “Back to School” 分類，賣家現在備貨還為時不晚。當然，除了學校用品在返校季賣得很好，賣家也應嘗試查看其它可以銷售的產品。預計學生會趁8月份開學前進行一些暑假活動，這就導致許多買家直到最後一刻才開始準備返校需要用到的產品。家長和大學生會湧向亞馬遜查找學習用品，而且他們也會尋找最新的電子產品，服裝和書籍等。值得注意的是，賣家越早將產品寄送到FBA倉庫，所獲得的獲得銷售的機會就越大。
      </p>
      <p>
        轉載至雨果網
      </p>
    </>
  )
}

export default Post8;