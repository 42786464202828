import React from 'react';
import { Card, Row, Col } from 'antd';
import IndexStyle from '../styles/pages/index.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Service_DL_1 from '../img/Service_DL_1.png';
import Service_DL_2 from '../img/Service_DL_2.png';
import Service_DL_3 from '../img/Service_DL_3.png';
import Service_DL_4 from '../img/Service_DL_4.png';
import Service_DL_5 from '../img/Service_DL_5.png';
import Service_Ful_1 from '../img/Service_Ful_1.png';
import Service_Ful_2 from '../img/Service_Ful_2.png';
import Service_Ful_3 from '../img/Service_Ful_3.png';
import Service_Ful_4 from '../img/Service_Ful_4.png';
import Index_news_01 from '../img/Index_news_01.jpg';
import Index_news_02 from '../img/Index_news_02.jpg';
import Index_news_03 from '../img/Index_news_03.jpg';
import Fedex from  '../img/Fedex.png';
import dpd from '../img/dpd.png';
import Royal_mail from '../img/Royal_mail.png';
import Fastway from '../img/Fastway.png';
import Ups from '../img/Ups.png';
import TNT from '../img/TNT.png';
import Correos from '../img/Correos.png';
import Colissimo from '../img/Colissimo.png';
import DeutschePost from '../img/DeutschePost.png';
import Hermes from '../img/Hermes.png';
import AustraliaPost from '../img/AustraliaPost.png';
import USPS from '../img/USPS.png';
import ebay from  '../img/ebay.png';
import amazon from '../img/amazon.png';
import KickStarter from '../img/KickStarter.png';
import Indiegogo from '../img/Indiegogo.png';
import Cdiscount from '../img/Cdiscount.png';
import wish from '../img/wish.png';
import Aliexpress from '../img/Aliexpress.png';
import Walmart from '../img/Walmart.png';
import newegg from '../img/newegg.png';
import Rakuten from '../img/Rakuten.png';
import PriceMinister from '../img/PriceMinister.png';
import MetaTags from 'react-meta-tags';

const { Meta } = Card;

const Index = () => {
  const { t, i18n } = useTranslation();
  const settings = {
    autoplay: false,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const settings_partners = {...settings, arrows: false};

  return(
    <>
      <MetaTags>
        <title>
          {
            i18n.language === 'en' || i18n.language === 'jp' ?
            'Continental Global Service Ltd - One-Stop eCommerce Logistics Service' :
            'Continental Global Service Limited - 一站式跨境電商物流'
          }
        </title>
      </MetaTags>
      <div className={`index ${IndexStyle.index}`}>
        <div className={`banner ${IndexStyle.banner}`}>
          <Slider {...settings}>
            <div>
              <div className={ IndexStyle.banner_02 }>
                <div className={ IndexStyle.text }>
                  {
                    i18n.language === 'en' || i18n.language === 'jp' ?
                    <h3 className={ IndexStyle.enjp }>{ t('index.banner.1.title') }</h3>
                    :
                    <h3>跨越界限&nbsp;&nbsp;&nbsp;速達全球</h3>
                  }
                  <Link className={ IndexStyle.readMoreBtn } to="/contactus">
                    { t('index.banner.btn') }
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <div className={ IndexStyle.banner_03 }>
                <div className={ IndexStyle.text }>
                  <h3 className={ i18n.language === 'en' ? IndexStyle.en : IndexStyle.jp }>{ t('index.banner.2.title') }</h3>
                  <Link className={ IndexStyle.readMoreBtn } to="/contactus">
                    { t('index.banner.btn') }
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        <div className="wrap">
          <div className={ IndexStyle.content }>
            <section className={ IndexStyle.service }>
              <h3>{ t('index.directLine.title') }</h3>
              <ul className={ IndexStyle.item }>
                <li>
                  <Link to="/service">
                    <img src={ Service_DL_1 } alt="Service_DL_1" />
                  </Link>
                  <p>{ t('index.directLine.img.1') }</p>
                </li>
                <li>
                  <Link to="/service">
                    <img src={ Service_DL_2 } alt="Service_DL_2" />
                  </Link>
                  <p>{ t('index.directLine.img.2') }</p>
                </li>
                <li>
                  <Link to="/service">
                    <img src={ Service_DL_3 } alt="Service_DL_3" />
                  </Link>
                  {
                    i18n.language === 'en' || i18n.language === 'jp' ?
                    <p>{ t('index.directLine.img.3') }</p>
                    :
                    <p>倉庫根據貨件<br />目的地分類</p>
                  }
                </li>
                <li>
                  <Link to="/service">
                    <img src={ Service_DL_4 } alt="Service_DL_4" />
                  </Link>
                  <p>{ t('index.directLine.img.4') }</p>
                </li>
                <li>
                  <Link to="/service">
                    <img src={ Service_DL_5 } alt="Service_DL_5" />
                  </Link>
                  <p>{ t('index.directLine.img.5') }</p>
                </li>
              </ul>
              <h3>{ t('index.fulfillment.title') }</h3>
              <ul className={ IndexStyle.item }>
                <li>
                  <Link to="/service">
                    <img src={ Service_Ful_1 } alt="Service_Ful_1" />
                  </Link>
                  {
                    i18n.language === 'en' || i18n.language === 'jp' ?
                    <p>{ t('index.fulfillment.img.1') }</p>
                    :
                    <p>買家在銷售<br />平台下單</p>
                  }
                </li>
                <li>
                  <Link to="/service">
                    <img src={ Service_Ful_2 } alt="Service_Ful_2" />
                  </Link>
                  {
                    i18n.language === 'en' || i18n.language === 'jp' ?
                    <p>{ t('index.fulfillment.img.2') }</p>
                    :
                    <p>Continental<br />系統接收訂單</p>
                  }
                </li>
                <li>
                  <Link to="/service">
                    <img src={ Service_Ful_3 } alt="Service_Ful_3" />
                  </Link>
                  {
                    i18n.language === 'en' || i18n.language === 'jp' ?
                    <p>{ t('index.fulfillment.img.3') }</p>
                    :
                    <p>Continental<br />海外倉庫出貨</p>
                  }
                </li>
                <li>
                  <Link to="/service">
                    <img src={ Service_Ful_4 } alt="Service_Ful_4" />
                  </Link>
                  <p>{ t('index.fulfillment.img.4') }</p>
                </li>
              </ul>
            </section>
          </div>
        </div>

        <section className={ IndexStyle.count }>
          <ul className={`wrap ${ IndexStyle.list }`}>
            <li>
              <p><span></span>2008</p>
              <h4>{ t('index.count.title.1') }</h4>
            </li>
            <li>
              <p><span>></span>300</p>
              <h4>{ t('index.count.title.2') }</h4>
            </li>
            <li>
              <p><span>></span>30K</p>
              <h4>{ t('index.count.title.3') }</h4>
            </li>
            <li>
              <p><span>></span>10M</p>
              <h4>{ t('index.count.title.4') }</h4>
            </li>
          </ul>
        </section>

        {
          i18n.language === 'en' || i18n.language === 'jp' ?
          null :
          <section className={ IndexStyle.news }>
            <div className="wrap">
              <h3>最新消息</h3>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Card
                      style={{ height: 310 }}
                      className={ IndexStyle.newsItem }
                      cover={
                        <Link to="/blog/33">
                          <img src={ Index_news_01 } alt="Index_news_01" />
                        </Link>
                      }
                  >
                    <Meta
                        title={
                          <Link to="/blog/33">
                            <h2>Continental正式宣布與UPS攜手合作，為台灣賣家提供全球運輸方案</h2>
                          </Link>
                        }
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Card
                    style={{ height: 310 }}
                    className={ IndexStyle.newsItem }
                    cover={
                      <Link to="/blog/32">
                        <img src={ Index_news_02 } alt="Index_news_02" />
                      </Link>
                    }
                  >
                    <Meta
                      title={
                        <Link to="/blog/32">
                          <h2>海外倉庫網絡 覆蓋全球</h2>
                        </Link>
                      }
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Card
                    style={{ height: 310 }}
                    className={ IndexStyle.newsItem }
                    cover={
                      <Link to="/blog/31">
                        <img src={ Index_news_03 } alt="Index_news_03" />
                      </Link>
                    }
                  >
                    <Meta
                      title={
                        <Link to="/blog/31">
                          <h2>亞馬遜美國網站悄悄支援繁體中文 香港和台灣消費者購物...</h2>
                        </Link>
                      }
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          </section>
        }

        <div className={ IndexStyle.partners }>
            <div className="wrap">
              <Slider {...settings_partners}>
                <div>
                  <h3>{ t('index.partners.title.1') }</h3>
                  <div className={ IndexStyle.logo }>
                    <img src={ Fedex } alt="Fedex" />
                    <img src={ dpd } alt="dpd" />
                    <img src={ Royal_mail } alt="Royal_mail" />
                    <img src={ Fastway } alt="Fastway" />
                    <img src={ Ups } alt="Ups" />
                    <img src={ TNT } alt="TNT" />
                    <img src={ Correos } alt="Correos" />
                    <img src={ Colissimo } alt="Colissimo" />
                    <img src={ DeutschePost } alt="DeutschePost" />
                    <img src={ Hermes } alt="Hermes" />
                    <img src={ AustraliaPost } alt="AustraliaPost" />
                    <img src={ USPS } alt="USPS" />
                  </div>
                </div>
                <div>
                  <h3>{ t('index.partners.title.2') }</h3>
                  <div className={ IndexStyle.logo }>
                    <img src={ ebay } alt="ebay" />
                    <img src={ amazon } alt="amazon" />
                    <img src={ KickStarter } alt="KickStarter" />
                    <img src={ Indiegogo } alt="Indiegogo" />
                    <img src={ Cdiscount } alt="Cdiscount" />
                    <img src={ wish } alt="wish" />
                    <img src={ Aliexpress } alt="Aliexpress" />
                    <img src={ Walmart } alt="Walmart" />
                    <img src={ newegg } alt="newegg" />
                    <img src={ Rakuten } alt="Rakuten" />
                    <img src={ PriceMinister } alt="PriceMinister" />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
      </div>
    </>
  )
}

export default Index;
