import React from 'react';
import img1 from '../../img/Article31_img1.jpg';
import img2 from '../../img/Article31_img2.jpg';
import img3 from '../../img/Article31_img3.jpg';
import img4 from '../../img/Article31_img4.jpg';
import img5 from '../../img/Article31_img5.jpg';

const Post31 = () => {
  return(
    <>
      <img src={ img1 } alt="img1" />
      <p>
        對於喜歡在美國亞馬遜網站購物的朋友有福了!以往可能都需要請在美國的朋友幫你代購回香港和台灣，現在你到美國亞馬遜將會變得更簡單，因為現在終於支援中文介面了!
      </p>
      <p>
        美國電商龍頭亞馬遜近期被發現開始支援繁體中文，其實原本美國亞馬遜網站就支援了英文、西班牙文、德文、葡萄牙文和簡體中文。對於香港和台灣的消費者來說，簡體中文已經比西班牙文、德文等語言更容易閱讀，但是繁體中文依舊比較親切而且自然。現在，只要打開瀏覽器進入美國亞馬遜，就可以看見首頁上方橫幅顯示’’現在您可以使用繁體中文在Amazon上購物’’並且網站還顯示目前網站上共有4500萬個產品可以直送台灣和香港，對於喜愛網購和美國當地才特有產品來說，是個很不錯的服務。
      </p>
      <img src={ img2 } alt="img2" />
      <p>
        根據部分消費者的體驗，美國亞馬遜支援繁體中文的部分主要是將商品名稱、選項、說明改成繁體中文。然而使用者的評論和主要商品的介紹並沒有完全翻譯成繁體中文。不過相較於英文、德文、西班牙文等等這類不同語系來說，繁體中文介面也親民和自然不少。不過比較遺憾的部分，吸引不少香港和台灣消費者的日本亞馬遜目前還沒有支援繁體中文的部分，或許在往後的日子會是大家期盼的新服務!
      </p>
      <img src={ img3 } alt="img3" />
      <p>
        那到美國亞馬遜網站購物會有哪些好處呢?許多商品的售價換算成港幣或台幣再加上運費，可能不會比香港或台灣購買還便宜，但如果想買一些美國獨賣的東西，現在透過Amazon購物就很方便!雖然每樣商品的國際運費會因重量、體積等等有不同的價格，部分商品還有機會無法運送至香港和台灣。舉例來說，不少喜歡閱讀的消費者所關心電子書，目前亞馬遜美國網站雖然有支援繁體中文，但是中文電子書並未上架。至於官網目前顯示Kindle系列機種無法運送至香港台灣地區。這時候就可以運用唯高盛集團旗下Buyippee買+易的服務! 
      </p>
      <img src={ img4 } alt="img4" />
      <p>
        Buyippee 很了解許多商品只有在國外才買的到，又或者價格比國內優惠許多，但是我們更瞭解在國外網站購物時您可能會面臨的問題，諸如語言隔閡、付款方式、高昂的國際運費及不確定的運送時程等，為了解決這些難題，於是買+易成立了! 買+易提供是一站式的全球代購代運服務，您可以輕鬆地上網搜尋喜愛的商品，只需將您想訂購的商品連結貼到我們的訂單系統，並完成訂金支付，剩下的就放心交給我們處理囉。
      </p>
      <p>
        Buyippee網站： <a href="https://www.buyippee.com.tw/" target="_blank" rel="noreferrer">https://www.buyippee.com.tw/ </a>
      </p>
      <img src={ img5 } alt="img5" />
    </>
  )
}

export default Post31;