import React from 'react';
import img1 from '../../img/Article19_img1.jpg';

const Post19 = () => {
  return(
    <>
      <p>
        土耳其人口年齡結構與歐洲國家相比有明顯年輕化的現象，勞動人口佔全國68%，大約5300萬人。根據世界銀行的統計，2017年土耳其有67.7%市民都有使用互聯網的習慣，相比2016增長了6.3%。在土耳其有1.6萬個電商網站，其中前六大電商僅佔市場的35.7%，以銷售第一的電商網店Hepsiburda只佔11.7%，其餘電商均未超過10%。根據官方數字，土耳其人口大約8000萬人。自從Amazon在1995年推出後，數百萬的訂單都來自土耳其的買家。由此可見，土耳其站的設立是刻不容緩，Amazon同時亦打算在當地建立倉庫和辦公部門。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        Amazon為了建立土耳其站，投資超過數十億美元在基礎設施和技術服務，協助中小型企業在全球吸引新客戶。隨著Amazon.com.tr的推出，土耳其企業可以通過我們的網上平台輕鬆地向各地的客戶銷售他們的產品，而同時無需繳交產品上架費。為了幫助跨境電商賣家，Amazon為企業提供不同類型的服務，包含有簡單的上架工具，全天候在線銷售商服務，開放和透明的銷售條件和定價，以及成長的報告和分析。
      </p>
      <p>
        目前土耳其的Amazon設有10個分類，包括書籍、電子產品、電腦和辦公室用品、手機及配件、家居和廚房用品、個人護理、建築用品、遊戲機、體育用品。早前Amazon已聯繫土耳其的零售商，邀請他們進駐平台。現在，除了來自當地土耳其品牌的產品，還有一直備受眾人喜歡的大品牌。
      </p>
      <p>
        據調查顯示，中國出口到土耳其的貨物主要有電子產品、賤金屬及製品、紡織品及原料、化工產品、家具玩具、塑料橡膠等。若有興趣進駐土耳其Amazon，需詳細了解當地對貿易進口產品徵收五種稅項，包含海關關稅、貨物稅、民眾住宅基金稅（針對魚類產品）、特別消費稅和增值稅。
      </p>
      <p>
        現時土耳其電子商務快遞服務商主要有土耳其郵政、Yurtic Kargo、Aras和MNG Kargo。而土耳其只有一個國際郵件轉運站，設立於伊斯坦布爾機場，因此寄往土耳其的國際包裹都必須經過伊斯坦布爾機場才能派送到目的地。在土耳其消費者最大忍受程度為4天左右，一般快遞都能夠在1-3個工作天到達，因此電商賣家選擇進入土耳其市場時，最好選擇時效性較強的第三方物流服務，協助處理各種問題同時，又能縮短派送時間。
      </p>
    </>
  )
}

export default Post19;