import React from 'react';
import img1 from '../../img/Article9_img1.jpg';

const Post9 = () => {
  return(
    <>
      <p>
        毋庸置疑的是東南亞市場將會成為電商巨頭們的下一個戰場，身為電商的你應該關注一下這個市場的金流服務。現時東南亞的商務支付方式和監管根據不同國家各有不同。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        <span>印尼</span><br />
        雖說印尼每年網絡使用者增長率高達20%，但消息者對電子金流的安全性有著很大疑慮。由於盜刷和資料外洩等事件層出不窮，導致印尼民眾對線上刷卡望而卻步。雖說印尼在過去5年信用上業務增長了60%，但有49%印尼人並不信任線上支付，導致目前70%的印尼消費者在網上消費時較傾向於轉賬付款或到便利店付款。因此，政府需給予當地市民適當的教育，並配合符合當地需求的網交易環境才能盡快提升市民對金流的信任度。
      </p>
      <p>
        <span>泰國</span><br />
        相對於東南亞其他國家，泰國的付款方式比較複雜。與印尼相同，消費者對於線上購物並不信任，因此雖然信用卡穿透率每年逐漸成長，但使用信用卡線上結帳不到1%。與此同時，泰國人並不支持「先洗未來錢」，所以他們亦有另一種較特別的支付方法 – COD (Cash on Delivery) 。這樣發貨速度相比用信用卡支付快上1天。
      </p>
      <p>
        <span>新加坡</span><br />
        新加坡電商大多以線上支付為主，現金支付遠低於東南亞其他國家，而信用卡使用量恰恰相反。以新加坡人較常用的電商平台為例，當中只有Lazada和Zalora支持貨到付款，而新加坡並不追捧在零售店現金支付，僅有Qoo10支援7-Eleven便利店現金支付。
      </p>
      <p>
        <span>馬來西亞</span><br />
        現時大部份馬來西亞人都擁有1-2張信用卡，50%網絡消費使用信用卡線上結帳，30%使用銀行轉帳，而20%選擇貨到付款。使用信用卡在網上消費相對新加坡消費者來說不是這麼普及，他們有一個得特別的支付方式，就是銀行轉帳。在馬來西亞的電子商務平台上的結帳頁面有提供不同銀行的帳號，點選自身帳戶的銀行後再輸入密碼便完成付款。
      </p>
      <p>
        總括而言，信用卡網上付款對於東南亞地區來說比較稀少，絕大部原因都歸究於對網上刷卡的不信任。這並不能一朝一夕改變，需要長期對市民教育以及制度的配合，方能令金流與電商一起發展。
      </p>
    </>
  )
}

export default Post9;