import React from 'react';
import img1 from '../../img/Article23_img1.jpg';
import img2 from '../../img/Article23_img2.jpg';

const Post23 = () => {
  return(
    <>
      <img src={ img1 } alt="img1" />
      <p>
        美國亞馬遜(Amazon)於11日表示，已和蘋果(Apple)達成協議，將在全球販賣更多蘋果的產品，未來幾周會上線美國、英國、法國、義大利、西班牙、日本、印度等全球電商平台。包含最新的iPhone XS系列、iPhone XR平板電腦iPad等產品。在過去亞馬遜網站上僅能買到部分蘋果產品，例如Mac和Beats耳機。
      </p>
      <p>
        這項協定顯示繼NIKE這類長期拒絕透過亞馬遜銷售的頂尖品牌，越來越願意透過亞馬遜來販售，畢竟在現今這也是能接觸顧客的關鍵管道。而亞馬遜也對其網站上盜版商品採取強硬立場。若未經蘋果官方授權，於2019年1月4日，亞馬遜將會移除第3方蘋果產品頁面，不過消費者仍然可以買到像耳機等與蘋果設備裝置相容的非官方配件。
      </p>
      <p>
        但在這項協議之下，並非所有的蘋果產品都能在亞馬遜上買到，例如被亞馬遜視為智慧音響Echo競爭對手的蘋果音響HomePod就無法在亞馬遜上購買。
      </p>
      <p>
        蘋果也在此項聲明提及：「我們正與亞馬遜合作，改善網站與蘋果用戶消費體驗，也期待消費者能有另外的管道可以購買iPhone、iPad、Apple Watch、Mac等產品」。
      </p>
      <p>
        知名研究網站eMarketer的分析師Andrew Lipsman則有不同的觀點，他認為蘋果向亞馬遜提出於該網站出售產品有一定的風險，但其實真正的風險是亞馬遜自家的產品受到競爭。亞馬遜和蘋果簽訂的與Nike簽訂的協議類似，對於小的商家、個人賣家和想買二手蘋果產品的人帶來傷害，這些人可能轉向亞馬遜競爭對手之一eBay好來販售蘋果產品。根據蘋果今年年度財務報告顯示，蘋果產品的銷售約71%來自亞馬遜等間接的銷售通路。
      </p>
      <img src={ img2 } alt="img2" />
      <p>
        除了引進蘋果產品，亞馬遜也強化自家物流服務，更祭出深度折扣，欲吸引更多賣家使用其今年開始試營的運輸服務。亞馬遜網站賣家 Ray Berman 近日獲邀運輸服務的試行計畫，他稱亞馬遜提供的運費比UPS還便宜50%之多。目前「亞馬遜運輸」服務僅在洛杉磯地區第三方賣家和亞馬遜倉庫間小包裹運輸，不包含運送至最終顧客端的尾程物流。不過，此試營計畫的賣家邀請函上稱現有價格是「推廣價」，暗示日後價格將上調，此外運送包裹的尺寸大小與重量也可能影響折扣比例。
      </p>
      <p>
        然而，亞馬遜的運輸成本不斷上升，2015 年 115 億美元，到了 2017 年已經增加至 217 億美元。這項紀錄顯示亞馬遜近年在物流方面有重大損失紀錄。去年，他們花費高達<a href="https://www.geekwire.com/2017/amazon-poised-spend-record-7-billion-least-shipping-holiday-quarter/" target="_blank" rel="noreferrer">200億美元</a>，只是為了確保商品有確實送到消費者手上。藉由內部物流措施，亞馬遜能更有效完整的控制並降低成本。除此之外物流業也充滿無限商機，如果亞馬遜能分到這塊大餅，這樣對自己的營收也是一大利多。
      </p>
    </>
  )
}

export default Post23;