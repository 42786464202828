import React from 'react';
import img1 from '../../img/Article33_img1.jpg';

const Post33 = () => {
  return(
    <>
        <img src={ img1 } alt="img1" />
        <p>
            我們很榮幸地宣布，Continental和UPS已正式成為合作夥伴，為台灣中小型電商賣家提供遍及全球220個國家的UPS運輸服務。
        </p>
        <p>
            透過Continental和UPS的合作，您將在註冊後享有使用UPS運輸服務最高達86%OFF的優惠折扣，包括在系統內建立訂單、列印UPS標籤、追蹤包裹等多項服務。
        </p>
        <p>
            其中最大的優勢在於您無需再擔心繁雜的運輸合約和最低貨運量等限制，輕鬆使用UPS將您的貨物送達全世界。
        </p>
        <p>
            CBES集團總裁暨Continental負責人Stanley表示，疫情後全球的電商企業正蓄勢待發，而台灣賣家也懷抱著積極布局全球的心情。我們感到非常榮幸能為台灣市場提供全方位的UPS全球運輸服務，讓台灣賣家以最便捷的方式滿足業務上的物流需求。Continental將站在所有賣家的角度，致力於協助將貨物運送至全世界，期盼攜手共創疫情後的另一波事業高峰。
        </p>
        <p>
            如欲了解更多服務內容，歡迎來信至我們台灣團隊的信箱：twcs@contin-global.com。
        </p>
    </>
  )
}

export default Post33;