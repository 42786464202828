import React from 'react';
import img1 from '../../img/Article12_img1.jpg';
import img2 from '../../img/Article12_img2.jpg';

const Post12 = () => {
  return(
    <>
      <p>
        為期三天的第三屆中國（廣州）跨境電商暨跨境商品選品展完滿結束，感謝不少客戶入場支持我們的展覽攤位。是次展覽會有超過300個參展商進駐會場，分為進口電商和出口電商，我們不幸地被分配到「進口電商」區，但這亦無懼我們的熱情，不少商家都對我們的服務感興趣。由於我們攤位處於進口電商區，令我們與其他外國進口電商有不少交流。與我們鄰近的參展商是出口的日本藥品公司，內地的龐大市場吸引他們在中國發展電商業務。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        隨著中國人民生活水平和消費能力逐漸提高，消費觀念和消費行為正慢慢發生轉變，對於商品品質的需求不斷增強。消費者在追求國外更加豐富的商品品類的同時，國內頻繁爆發的奶粉、化妝品等商品質量問題以及食品安全問題，使得消費者對國外商品品質更為信任，由此興起了出境消費，牽引起海外代購的熱潮。但由於海淘、個人代購在貨源、價格、物流、服務諸多方面存在痛點，從而為跨境進口零售電商行業發展留出機會。同時，隨著國家政策的逐漸清晰與大力支持，使得跨境電商的發展進入了快車道，越發規範與迅速。根據官家統計數字，現時中國人口高達13.79億。而截至2017年底，中國網民規模達7.72億人，普及率為55.8%，人均每週上網時長為27個小時。中國網民選擇用手機上網比較多，在上網人群中佔比從2016年的95.1%上升到97.5%。中國網民的增長加上內地消費者對進口商品需求越趨殷切，不少進口電商把握機會紛紛進駐國內市場。
      </p>
      <p>
        <span>中國物流模式</span><br />
        現時跨境進口零售商有三種物流模式，分別為保稅、直郵和集運模式，其中以前二方法最為常用，而集運則是直郵的升級版，把貨品送到國外的集貨點再一起轉運到中國。以下為分析中國三大物流模式的利弊和適合用戶以供大家參考。
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        由於三種主流物流模式都各有利弊，雖然保稅模式相對上比較優勝，但同時要求亦較高，賣家商品數量需在短時間內大量增加，而直郵和集運模式則不限制商品種類。因此現時大多大型跨境電商都會因不同商品而選擇不同的運送方法，不會只單單選用一個運送模式。
      </p>
    </>
  )
}

export default Post12;