import React from 'react';
import img1 from '../../img/Article11_img1.jpg';

const Post11 = () => {
  return(
    <>
      <p>
        「共享經濟」已在全球發展了一段時間，不少企業的帶著共享價值思維，透過把物件的擁有權借出，而用戶只需繳付按金即可使用資源。這不但使物件能夠充分利用，不用閒置和浪費，同時亦有環保的概念，減少重複製造物品，減少浪費地球資源。最近的城中熱話相信非Gobee Bike退出香港市場莫屬。香港新界區山路較多，而且並不是每個地區都設有單車專用線，對用單車使用者來說的確不便。加上香港交通本已發達，市民對用「最後一里」的選擇大多是步行而非需尋找再解鎖的共享單車。因此與西方國家不同，香港市場並不適合共享單車生存。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        而在國內共享資源同樣十分盛行，能夠租借物品層出不窮，包括最基礎的共享單車、汽車、充電寶、雨傘、衣服等等，隨後甚至有共享男女友、籃球、折凳等等，似乎與共享的概念本末倒置，成為了「偽共享」。那究竟發展共享企業可行嗎？我認為是可行的，共享企業的收入來源主要是靠物品的按金，但企業必須謹慎考慮共享的物品種類。以共享單車為例，單車成本不低，加上單車較為容易耗損，維修費用形成一筆龐大開支。再者，單車的擺放位置本是由公司安排，隨後經過不用同戶的使用，導致有些單車的擺放位置並不方便大眾用戶，甚至違例。這時公司必須為單車承擔責任或投放人力資源去回收單車，「執車」成為共享單車企業的最大開支。由此可見，我認為共享單車並不能成為共享經濟的主流。
      </p>
      <p>
        現時國內共享單車的按金大約在100元，與之相同按金的有共享充電寶。我認為共享充電寶相比共享單車更為突出。先比較兩者的成本，擁有最基本設備的單車格價大約$2000，而充電寶則是$100。雖說國內有些充電寶共享企業並不收取按金，而是靠逾期罰款和廣告費維持收入，但由成本價格便得出充電寶相比單車較能承受遺失的風險。除此以外，單車在使用上較為容易折損；充電寶則無須擔心在使用上的損耗，用家亦不會故意折耗充電寶，折舊成本相比共享單車低。與共享單車一樣，共享充電寶無須原點歸還，但不同的是充電寶設有多個站點歸還，即大大減少了收集物品的人力成本。
      </p>
      <p>
        內行人士向我透露「我們公司在廣東省有一百個點共享充電寶供給市場，由於有6成用戶不會選擇退回按金，現有滾存按金已超過一百萬。」這驚人的數字顯示出大眾對共享充電寶存在一定的信心。若投資者有意在「共享經濟」拓展業務，不妨考慮一些較為便利的物品。
      </p>
    </>
  )
}

export default Post11;