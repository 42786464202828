import React from 'react';
import img1 from '../../img/Article4_img1.jpg';
import img2 from '../../img/Article4_img2.jpg';

const Post4 = () => {
  return(
    <>
      <p>
        中國國家主席習近平所提及的“一帶一路”相信大家都不陌生，為了凝聚跨國家、跨地區的電商產業鏈各個環節，歐洲及至全球最具影響力的德國電商論壇舉辦了“一帶一路中德姐妹電商團”揭牌暨Meet Magento國際電商論壇。活動舉辦目的是為了深化中德兩國之間的合作關係，讓深圳成為中國與歐洲的貿易窗口。此次論壇邀請了全球超過1300名各國電商企業家與德國工業4.0專家齊聚一堂，有幸代表香港電商協會參加是次揭牌儀式，實在是十分榮幸。
      </p>
      <img src={ img1 } alt="img1"/>
      <figcaption>
        與德國Meet Magento協會主席Thomas合照
      </figcaption>
      <p>
        工業4.0是指由德國提出的第四次工業革命，利用信息化技術促進產業變革的時代，即是智能化時代。現時工業4.0已到達中德合作的階段，從中德雙方簽署的《中德合作行動網要》中提及工業4.0對中德未來經濟發展具有重大意義，兩國政府都會為參與的企業提供政策上的支持。加上現時互聯網的發展趨向，形成網民都傾向網上購物多於親身到實體商店購買。由此可見，是次論壇不但促進中德國際貿易和國際資源互換，同時推進“一帶一路” 和工業4.0的建設，釋放兩國的發展潛力。
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        一連九天的行程十分緊湊，不論是演講抑或是與其他企業家對接都讓我有種海外遇知音的感覺。會內很多人都對中國的龐大市場有濃厚興趣，對於中國拓展電子商務貿易表示十分歡迎。作為跨境電商物流公司主席，早已明白到電商賣家對內地市場有著無限的渴求，相信經過今次活動不少人已對中國電商有更深入的認識。加上德國政府大力支持是次活動，本次活動成為了中德電子商務貿易的頭炮，中國電子商務蓬勃指日可待。
      </p>
    </>
  )
}

export default Post4;