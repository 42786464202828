import Index from '../pages/Index';
import Aboutus from '../pages/Aboutus';
import Remote from '../pages/Remote';
import Blog from '../pages/Blog';
import BlogPosts from '../pages/BlogPosts';
import Service from '../pages/Service';
import Location from '../pages/Location';
import Faq from '../pages/Faq';
import Logistic from '../pages/Logistic';
import Contactus from '../pages/Contactus';
import Career from '../pages/Career';
import Signup from '../pages/Signup';

export const RouteConfig = [
  { 
    path: "/",
    title: "首頁", 
    exact: true, 
    component: Index 
  },
  {
    path: "/aboutus",
    title: "關於我們",
    exact: false,
    component: Aboutus
  },
  {
    path: "/remote",
    title: "遙距營商計劃服務",
    exact: false,
    component: Remote
  },
  {
    path: "/blog",
    title: "電商日誌",
    exact: true,
    component: Blog
  },
  {
    path: "/blog/:postId",
    title: "電商日誌內頁",
    exact: true,
    component: BlogPosts
  },
  {
    path: "/service",
    title: "服務範疇",
    exact: false,
    component: Service
  },
  {
    path: "/location",
    title: "倉庫網絡",
    exact: false,
    component: Location
  },
  {
    path: "/faq",
    title: "常見問題",
    exact: false,
    component: Faq
  },
  {
    path: "/logistic",
    title: "專線物流",
    exact: false,
    component: Logistic
  },
  {
    path: "/contactus",
    title: "聯絡我們",
    exact: false,
    component: Contactus
  },
  {
    path: "/career",
    title: "人才招聘",
    exact: false,
    component: Career
  },
  {
    path: "/signup",
    title: "立即登記",
    exact: false,
    component: Signup
  }
]