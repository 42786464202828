import React from 'react';
import img1 from '../../img/Article29_img1.jpg';
import img2 from '../../img/Article29_img2.jpg';
import img3 from '../../img/Article29_img3.jpg';
import img4 from '../../img/Article29_img4.jpg';

const Post29 = () => {
  return(
    <>
      <img src={ img1 } alt="img1" />
      <p>
        受到中美貿易的影響，美股持續震盪，市值排名不斷洗牌，2018年底時，微軟取代蘋果登上美股市值第一的寶座，美國時間2019年1月7日美國股市延續漲勢，道瓊工業指數收長近百點，而電商龍頭亞馬遜漲幅3%市值約7990億美元，超越微軟(MSFT - US)約7910億美元成為全球市值第一。然而，曾經市值破兆的蘋果(AAPL – US)在經歷下修財測大跌後，如今已降至約6990億美元，就連Google母公司Alphabet(GOOGL - US)都以市值約7470億美元贏過蘋果。
      </p>
      <img src={ img2 } alt="img2" />
      <p>
        根據金融數據公司FactSet先前做的調查，分析師預估蘋果本季營收應能達到913美元。但蘋果營收卻低於預期9%，實非投資人樂見。庫克認為，中國市場是一大因素。中國近年經濟成長趨緩，中美貿易衝突，加上華為和小米，除了大中華地區外，其他地區對於IPhone新手機的渴求程度也逐漸降低。一部分的原因也是因為蘋果高端手機要價1000美元起跳，消費者較難接受。
      </p>
      <img src={ img3 } alt="img3" />
      <p>
        從去年10月初高點至今，蘋果股價已跌將近3成。這樣的情勢可能連帶影響概股表現。韋德布希證券公司(Wedbush Securities)分析師Daniel Ives表示，「這是庫克時代蘋果最黑暗的日子。」她認為蘋果錯估貿易暫形勢，不斷在大中華地區推出高端手機，讓其他競爭對手有機會搶佔中低階手機市場。現在必須看蘋果如何改變銷售策略，否則很難重現先前光彩。
      </p>
      <img src={ img4 } alt="img4" />
      <p>
        反觀亞馬遜，根據CNBC分析，亞馬遜有5項優勢促成其為目前市值第一。第一、雲端運算服務(Amazon Web Service)營收破230億美元，站雲端市場四成。AWS提共約140項服務，目前也有數百萬客戶。第二、亞馬遜去年電商銷量美國市占率將近50%，現今也積極拓展印度等市區市場。第三、亞馬遜旗下事業，比如智慧語音助理Alexa及健康、廣告事業皆表現亮眼。第四、不同其他科技業，亞馬遜領導階層多為較有經驗的管理者，向心力十足。執行長Jeff Bezos也曾強調，亞馬遜不會讓核心領導團隊S-Team出現過多的異動。最後第五、亞馬遜較少有大規模的發展。亞馬遜近來雖然也有不少爭議，包含來自美國總統川普的攻擊，尋覓第二總部時引發的傳言，但是這些都並未對其造成立即的危機。相較於蘋果與中國的糾葛、臉書Facebook的隱私權問題，亞馬遜的投資風險相對較低。
      </p>
    </>
  )
}

export default Post29;