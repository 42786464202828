import React from 'react';
import img1 from '../../img/Article7_img1.jpg';
import img2 from '../../img/Article7_img2.jpg';

const Post7 = () => {
  return(
    <>
      <p>
        現時網上購物成風，不少賣家都由實體店轉戰到網上商店，能夠省下成本之餘，更能擴展市場。為了迎合商家急促發展，不少歐美賣家早已進軍Amazon和ebay等電子商務平台。但當歐美市場已漸趨成熟，又有哪個市場能夠拓展？
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        2016的人口統計指出，東南亞人數高達6.3億，占全球總人口8.6%，種群的急促增長造成需求越來越大。加上東南亞為全球最大手機買賣市場，手機移動寬頻使用急促上升，相比其他已發展國家更甚。根據Google的數據顯示，東南亞人平均每人每日使用手機3.6小時。手機的普及令電子商務逐漸萌芽，雖說現時還不及其他發展國家，但2025年東南亞地區將會成為電子商務的主要市場。知名電子商務平台Amazon和Alibaba都擠身於東南亞市場，但仍不及在新加坡起家的Shopee。
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        當不少香港賣家都加入歐美市場時，在東南亞地區一個電子商務平台漸漸發展成熟。Shopee又名蝦皮購物，是近年急促發展的網上銷售平台。Shopee總部設立於新加坡，目前擴展到馬來西亞、菲律賓、泰國、越南、台灣、印尼等地區。Shopee本只有手機版，直至2015年才建立網頁版，介面與阿里巴巴的淘寶類近。這個網上銷售平台的最大優勢在於郵費大多是免費，而且亦無任何上架費或手續費，大大減低賣家運作成本。
      </p>
      <p>
        雖然蝦皮購物現時並沒有香港站點，但香港賣家亦可進駐台灣站點做賣家。由香港直接發貨到台灣或東南亞地區，善用香港本身作為轉口港的優勢。蝦皮購物操作上與其他大型電子商務平台差不多，能夠與買家在線上即時溝通，賣家亦毋需擔心時差問題。
      </p>
    </>
  )
}

export default Post7;