import React from 'react';
import CareerStyle from '../styles/pages/career.module.scss';
import Career_img from '../img/Career_img.jpg';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

const Career = () => {
  const { t, i18n } = useTranslation();

  return(
    <>
      <MetaTags>
        <title>
          {  
            i18n.language === 'en' ?
            'Continental Global Service Ltd - One-Stop eCommerce Logistics Service' :
            'Continental Global Service Limited - 一站式跨境電商物流 | 人才招聘'
          }
        </title>
      </MetaTags> 
      <div className={ CareerStyle.career }>
        <div className="banner-color">
          <h1 className="wrap">{ t('page.career') }</h1>
        </div>
        <div className="wrap content">
          <div className={ CareerStyle.img }>
            <img src={ Career_img } alt="Career_img" />
          </div>
          <div className={ CareerStyle.content }>
            <p>
              { t('career.text.1') }
            </p>
            <p>
            { t('career.text.2') } <a href="mailto:sales@contin-global.com">sales@contin-global.com</a> ({ t('career.text.3') })
            </p>
            <p>
            { t('career.text.4') }
            </p>
            <div className={ CareerStyle.item }>
              <h3>{ t('career.title.accounting') }</h3>
              <div className={ CareerStyle.section }>
                <div className={ CareerStyle.part }>
                  <h4>{ t('career.jobSummary') }</h4>
                  <ul>
                    <li>{ t('career.accounting.summary.1') }</li>
                    <li>{ t('career.accounting.summary.2') }</li>
                    <li>{ t('career.accounting.summary.3') }</li>
                    {
                      i18n.language === "en" ?
                      null :
                      <li>{ t('career.accounting.summary.4') }</li>
                    }
                  </ul>
                </div>
                <div className={ CareerStyle.part }>
                  <h4>{ t('career.requirements') }</h4>
                  <ul>
                    <li>{ t('career.accounting.requirements.1') }</li>
                    <li>{ t('career.accounting.requirements.2') }</li>
                    <li>{ t('career.accounting.requirements.3') }</li>
                    <li>{ t('career.accounting.requirements.4') }</li>
                    {
                      i18n.language === "en" ?
                      null :
                      <>
                        <li>{ t('career.accounting.requirements.5') }</li>
                        <li>{ t('career.accounting.requirements.6') }</li>
                        <li>{ t('career.accounting.requirements.7') }</li>
                        <li>{ t('career.accounting.requirements.8') }</li>
                      </>
                    }
                  </ul>
                </div>
              </div>
            </div>

            <div className={ CareerStyle.item }>
              <h3>{ t('career.title.sales') }</h3>
              <div className={ CareerStyle.section }>
                <div className={ CareerStyle.part }>
                  <h4>{ t('career.jobSummary') }</h4>
                  <ul>
                    <li>{ t('career.sales.summary.1') }</li>
                    <li>{ t('career.sales.summary.2') }</li>
                    <li>{ t('career.sales.summary.3') }</li>
                  </ul>
                </div>
                <div className={ CareerStyle.part }>
                  <h4>{ t('career.requirements') }</h4>
                  <ul>
                    <li>{ t('career.sales.requirements.1') }</li>
                    <li>{ t('career.sales.requirements.2') }</li>
                    <li>{ t('career.sales.requirements.3') }</li>
                    <li>{ t('career.sales.requirements.4') }</li>
                    <li>{ t('career.sales.requirements.5') }</li>
                    {
                      i18n.language === "en" ?
                      null :
                      <>
                        <li>{ t('career.sales.requirements.6') }</li>
                        <li>{ t('career.sales.requirements.7') }</li>
                        <li>{ t('career.sales.requirements.8') }</li>
                        <li>{ t('career.sales.requirements.9') }</li>
                      </>  
                    }
                  </ul>
                </div>
              </div>
            </div>

            <div className={ CareerStyle.item }>
              <h3>{ t('career.title.assistant') }</h3>
              <div className={ CareerStyle.section }>
                <div className={ CareerStyle.part }>
                  <h4>{ t('career.jobSummary') }</h4>
                  <ul>
                    <li>{ t('career.assistant.summary.1') }</li>
                    <li>{ t('career.assistant.summary.2') }</li>
                    <li>{ t('career.assistant.summary.3') }</li>
                  </ul>
                </div>
                <div className={ CareerStyle.part }>
                  <h4>{ t('career.requirements') }</h4>
                  <ul>
                    <li>{ t('career.assistant.requirements.1') }</li>
                    <li>{ t('career.assistant.requirements.2') }</li>
                    <li>{ t('career.assistant.requirements.3') }</li>
                    <li>{ t('career.assistant.requirements.4') }</li>
                    {
                      i18n.language === "en" ?
                      null :
                      <>
                        <li>{ t('career.assistant.requirements.5') }</li>
                        <li>{ t('career.assistant.requirements.6') }</li>
                      </>
                    }
                  </ul>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default Career