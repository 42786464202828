import React from 'react';
import img1 from '../../img/Article27_img1.jpg';
import img2 from '../../img/Article27_img2.jpg';
import img3 from '../../img/Article27_img3.jpg';
import img4 from '../../img/Article27_img4.jpg';
import img5 from '../../img/Article27_img5.jpg';

const Post27 = () => {
  return(
    <>
      <img src={ img1 } alt="img1" />
      <p>
        第二屆「創智營商博覽」(SmartBiz Expo)是由香港貿易發展局在灣仔會議展覽中心舉辦，主要針對中小型企業升級轉型、提升他們的競爭力和拓展全球市場的需要，提供一站式的支援。場內有不少展覽商參展，主要圍繞最新商業創新方案、應用科技、營運支援及管理等一站式平台。活動於12月5至7日舉行，博覽吸引超過520家參展商，分別來自70多個國家及地區，參與超過40場專題研討會。
      </p>
      <img src={ img2 } alt="img2" />
      <p>
        集團旗下兩家公司有幸參展是次展覽，分別是Continental Global Service和Avolution，在會場內不少賣家都對集團所提供的服務感興趣。由於時代變遷，不少傳統業務都被逼轉型電子商務開拓海外市場。無奈香港絕大部分賣家、廠家及品牌往往因不熟悉電商業務，導致無從入手而卻步。Avolution的成立目的是協助不熟悉電商市場的顧客迅速且有效地作銷售轉型，加上Continental所提供的安全穩定跨境物流一條龍服務，雙管齊下必定能為香港商家帶來新出路，務求做到貨通全球，成就跨境業務，創造品牌價值。
      </p>
      <img src={ img3 } alt="img3" />
      <p>
        第二屆的展覽活動共有三種不同的主題，在首日研討會主要圍繞著「工業4.0」，講解中小企業在工業4.0的革命下所需的設備、雲端數據分析和物聯網等課題。物流及供應鏈多元技術研發中心、應用科技研究院等也都在首日提供適合各行各業的工業4.0方案。
      </p>
      <p>
        翌日(12/6)的重點則擺在「智慧城市」，大會請來專家講解大數據和5G行動通訊，好比如何建構智慧城市，以及中小企業如何在智慧城市發展的過程中站穩及從中獲利。當日場內不少廠商的產品和技術都能配合智慧城市發展。
      </p>
      <img src={ img4 } alt="img4" />
      <p>
        最後一天焦點是「電子商務」，研討會分析多個從事電子商務需要了解的課題，包括目標客群、線上客服、跨境電子商務和最後一哩運送服務。多個商會及電子商務和物流服務供應商駐守場內，由品牌定位、開設網店、物流、以及宣傳和數據分析，協助中小企業開過電子商務。
      </p>
      <img src={ img5 } alt="img5" />
    </>
  )
}

export default Post27;