import React from 'react';
import img1 from '../../img/Article1_img1.jpg';
import img2 from '../../img/Article1_img2.jpg';

const Post1 = () => {
  return(
    <>
      <p>
        近年來，德國、法國、西班牙、意大利等歐洲國家的電商市場增長迅速，單以法國為例，據統計，當地2017年全年的電商營業額便比2016年增長69%。但由於英語並非這些國家的日常溝通語言，故使用當地語言作爲產品上架及客服用語，有助減低消費者的溝通障礙，提升他們者對本港電商的信任度及忠誠度，加上由於歷史原因，法語、西班牙語等在中南美洲及非洲多國為主流用語之一，故此市場上對相關語種的翻譯服務有一定需求。
      </p>
      <p>
        作為跨境電商物流公司，為滿足客戶開拓上述非英語市場的需要，為香港電商打開新市場，近年來積極開拓專業電商翻譯業務，為產品描述及上架，客戶服務提供專業的翻譯服務。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        為進一步提升本公司翻譯服務的質素，公司特於年4月23日簽與台灣知名的輔仁大學簽訂產學合作備忘錄。根據該備忘錄，是次合作由該校享負盛名的外語學院負責，該院管理層將定期與本公司有關部門召開會議，了解本公司對不同語種翻譯的需求，並以此為基礎，協助遴選轄下各外文系的優秀學員於本公司擔任翻譯員，為本公司提供的電商翻譯服務提供更佳的質量保證。
      </p>
      <p>
        此外，為配合翻譯業務的長遠發展，本公司亦將透過是次與輔仁大學的合作，作爲儲備人才計劃的其中一部分，通過本公司的審核的各外文翻譯系學員，於其畢業後將獲本公司招聘為正式員工，為本公司進一步開拓專業電商翻譯業務提供源源不絕的動力。
      </p>
      <img src={ img2 } alt="img2"/>
      <figcaption>
        輔仁大學外語學院賴振南院長與本公司創辦人暨董事總經理李基銓先生簽署合作備忘錄
      </figcaption>
    </>
  )
}

export default Post1;