module.exports = [
  {
      "key": 1,
      "title": "與台灣輔仁大學合作",
      "time": "2018年5月29日",
      "description": 
        "近年來，德國、法國、西班牙、意大利等歐洲國家的電商市場增長迅速，單以法國為例，據統計，當地2017年全年的電商營業額便比2016年增長69%。但由於英語並非這些國家的日常溝通語言，故使用當地語言作爲產品上架及客服用語，有助減低消費者的溝通障礙，提升他們者對本港電商的信任度及...",
      "image": require("../img/Blog_list_01.jpg")
  },
  {
      "key": 2,
      "title": "輔仁大學邀請擔任學校委員",
      "time": "2018年6月11日",
      "description": 
        "在上一篇電商日誌提及我們在4月23日與台灣知名的輔仁大學簽訂產學合作備忘錄，該校將協助挑選來自外文系的優秀學員加入本公司擔任翻譯員。我們近日更有幸獲得輔大外語學院的邀請，委派一位代表加入該學院產業實習委員會擔任委員，檢討學院產業實習計劃的成效以及未來的規劃。...",
      "image": require("../img/Blog_list_02.jpg")
  },
  {
      "key": 3,
      "title": "Shopee香港賣家沙龍",
      "time": "2018年6月14日",
      "description": 
        "本公司十分榮幸能被在東南亞首屈一指的電商平台Shoppee 邀請，協助該公司舉辦在香港舉行的首場賣家沙龍。其實，國際知名電商平臺Amazon及Wish 當年在香港的首場簡介會，也是由本公司協助，足見我們在電商物流的豐富經驗被各大電商平台所認可。...",
      "image": require("../img/Blog_list_03.jpg")
  },
  {
      "key": 4,
      "title": "中德Meet Magento國際電商論壇",
      "time": "2018年6月20日",
      "description": 
        "中國國家主席習近平所提及的“一帶一路”相信大家都不陌生，為了凝聚跨國家、跨地區的電商產業鏈各個環節，歐洲及至全球最具影響力的德國電商論壇舉辦了“一帶一路中德姐妹電商團”揭牌暨Meet Magento國際電商論壇。活動舉辦目的是為了深化中德兩國之間的合作關係，讓深圳成為中國與...",
      "image": require("../img/Blog_list_04.jpg")
  },
  {
      "key": 5,
      "title": "亞馬遜推出「亞馬遜全球收款」",
      "time": "2018年6月27日",
      "description": 
        "Amazon在前日推出了「亞馬遜全球收款」(Amazon Currency Converter for Sellers)，服務主要便利內地賣家。開通後再不需要開設外國銀行帳戶抑或第三方帳戶，可以直接在國內銀行戶口收款，為國內跨境賣家帶來便利、快速和安全的賣買體驗。服務剛推出...",
      "image": require("../img/Blog_list_05.jpg")
  },
  {
      "key": 6,
      "title": "香港本土電商被捨棄了嗎？",
      "time": "2018年7月3日",
      "description": 
        "談到電商，普羅大眾的印象都是國內的銷售平台，但其實香港的電商產業已老早發展，但由於本地電商市場細，發展不蓬勃，加上經營困難，令我們都忽略了它。事實上，香港是一個自由港的身分，奉行自由貿易政策，並無設置任何貿易壁壘，進出香港的貨品毋須繳付關稅，應能為本地電商業帶來方便。電商規...",
      "image": require("../img/Blog_list_06.jpg")
  },
  {
      "key": 7,
      "title": "東南亞新興購物平台 - 蝦皮購物(Shopee)",
      "time": "2018年7月9日",
      "description": 
        "現時網上購物成風，不少賣家都由實體店轉戰到網上商店，能夠省下成本之餘，更能擴展市場。為了迎合商家急促發展，不少歐美賣家早已進軍Amazon和ebay等電子商務平台。但當歐美市場已漸趨成熟，又有哪個市場能夠拓展？ 2016的人口統計指出，東南亞人數高達6.3億，占全球總人口8...",
      "image": require("../img/Blog_list_07.jpg")
  },
  {
      "key": 8,
      "title": "如何在淡季增加Amazon銷量？",
      "time": "2018年7月12日",
      "description": 
        "距離感恩節還有三個多月，電商賣家雖則要為貿易旺季做好準備，但亦不能只看長線，不為淡季作任何打算。其實在夏季要推動Amazon產品銷量很簡單，你需要以下這幾個方法： 1. 增加FBA倉儲 FBA賣家常用術語 “Feed the Beast”...",
      "image": require("../img/Blog_list_08.jpg")
  },
  {
      "key": 9,
      "title": "你了解東南亞的金流嗎？",
      "time": "2018年7月16日",
      "description": 
        "毋庸置疑的是東南亞市場將會成為電商巨頭們的下一個戰場，身為電商的你應該關注一下這個市場的金流服務。現時東南亞的商務支付方式和監管根據不同國家各有不同。 印尼 雖說印尼每年網絡使用者增長率高達20%，但消息者對電子金流的安全性有著很大疑慮。由於盜刷和資料外洩等事件層出不窮，導...",
      "image": require("../img/Blog_list_09.jpg")
  },
  {
      "key": 10,
      "title": "你必需知道的東南亞物流服務",
      "time": "2018年7月18日",
      "description": 
        "上文已有提及到東南亞的金流，身為跨境電商的你，不但要對當地的金流了解，你還需要知道東南亞的物流資訊。由於東南亞地區的電商業務還處於發展中階段，加上國家大多是由不同海島組成，導致物流服務還遠遠不夠歐美國家成熟。根據世界銀行在2016年的公佈的《物流績效指數》顯示，東盟五國的排...",
      "image": require("../img/Blog_list_10.jpg")
  },
  {
      "key": 11,
      "title": "共享經濟還能走多遠",
      "time": "2018年7月24日",
      "description": 
        "「共享經濟」已在全球發展了一段時間，不少企業的帶著共享價值思維，透過把物件的擁有權借出，而用戶只需繳付按金即可使用資源。這不但使物件能夠充分利用，不用閒置和浪費，同時亦有環保的概念，減少重複製造物品，減少浪費地球資源。最近的城中熱話相信非Gobee...",
      "image": require("../img/Blog_list_11.jpg")
  },
  {
      "key": 12,
      "title": "中國「進口電商」？",
      "time": "2018年7月30日",
      "description": 
        "為期三天的第三屆中國（廣州）跨境電商暨跨境商品選品展完滿結束，感謝不少客戶入場支持我們的展覽攤位。是次展覽會有超過300個參展商進駐會場，分為進口電商和出口電商，我們不幸地被分配到「進口電商」區，但這亦無懼我們的熱情，不少商家都對我們的服務感興趣。由於我們攤位處於進口電商區...",
      "image": require("../img/Blog_list_12.jpg")
  },
  {
      "key": 13,
      "title": "電商賣家的金流好幫手─Vcan",
      "time": "2018年8月2日",
      "description": 
        "目前，跨境電商雖然仍呈現迅猛發展的態勢，但是生意卻越來越難做。一方面是平台門檻的提高對賣家增添不少限制；另一方面，行業競爭加劇導致中小賣家的利潤空間縮減。來自香港的跨境收款黑馬平台Vcan，提供封頂0.5%收費，令電商賣家備貨無憂，提升賣家競爭力。...",
      "image": require("../img/Blog_list_13.jpg")
  },
  {
      "key": 14,
      "title": "美國國會議員致Amazon的信",
      "time": "2018年8月7日",
      "description": 
        "Amazon Prime Day賣家都忙著備貨，但同時Amazon卻收到一封由美國國會議員Keith Ellison的信，信中批擊Amazon容許賣家提倡和宣傳仇恨、暴力、種族主義、性和宗教，縱容在平台上出售有關商品，包括兒童玩具、衣服和Kindle書籍。同時，Elliso...",
      "image": require("../img/Blog_list_14.jpg")
  },
  {
      "key": 15,
      "title": "哪個跨境電商平台最合適？（上）",
      "time": "2018年8月14日",
      "description": 
        "若你已經擁有自營實體商店和網上商店，你必需為你的事業增添銷售渠道，使商品能夠被更多人發掘到。但並不是每個銷售平台都適合每間商店，你需要根據你的商店特質和你的目標客戶去選擇相符的平台。而且大多數平台都會根據你的商品數量或訂單收取對應的銷售佣金，所以你必須謹慎選擇網上商城。當然...",
      "image": require("../img/Blog_list_15.jpg")
  },
  {
      "key": 16,
      "title": "哪個跨境電商平台最合適？（下）",
      "time": "2018年8月20日",
      "description": 
        "在哪個跨境電商平台最合適？（上）我們已分享了4個跨境平台，包含Amazon、eBay、Walmart和Etsy。本篇繼續分享4個跨境電商平台。 Shopify 現時全球超過40萬家中小企都使用Shopify的服務，讓商家能夠客製化自已的品牌電商網站。使用Shopify建立專...",
      "image": require("../img/Blog_list_16.jpg")
  },
  {
      "key": 17,
      "title": "美媒點贊阿裡巴巴國際站：用數位化新技術改變購物方式",
      "time": "2018年8月27日",
      "description": 
        "“阿裡巴巴國際站改變我們的購物方式”。日前，阿裡巴巴國際站（Alibaba.com）亮相全美最具影響力的拉斯維加斯國際服裝服飾博覽會(MAGIC SHOW)，席間展示的多項數位化全新購物體驗功能，獲美國哥倫比亞廣播公司（CBS）等美媒大力稱讚。...",
      "image": require("../img/Blog_list_17.jpg")
  },
  {
      "key": 18,
      "title": "普京東方經濟論壇兩度盛讚阿裡巴巴 稱其正加速俄羅斯電商發展",
      "time": "2018年9月17日",
      "description": 
        "“我們同阿裡巴巴的簽約是中俄間極具代表性的合作，將加速為俄羅斯電商發展帶來一系列改變。”在9月12日東方經濟論壇全體會議上，俄羅斯總統普京特別提到了阿裡巴巴與俄三巨頭成立合資公司的消息，強調此舉是...",
      "image": require("../img/Blog_list_18.jpg")
  },
  {
      "key": 19,
      "title": "Amazon土耳其站投入服務 不收Listing費用",
      "time": "2018年9月24日",
      "description": 
        "土耳其人口年齡結構與歐洲國家相比有明顯年輕化的現象，勞動人口佔全國68%，大約5300萬人。根據世界銀行的統計，2017年土耳其有67.7%市民都有使用互聯網的習慣，相比2016增長了6.3%。在土耳其有1.6萬個電商網站，其中前六大電商僅佔市場的35.7%，以銷售第一的電...",
      "image": require("../img/Blog_list_19.jpg")
  },
  {
      "key": 20,
      "title": "Facebook活動「2020與世界為友」 台灣總統上台表示：讓世界透過Facebook看到台灣的好",
      "time": "2018年10月3日",
      "description": 
        "Facebook可以說是全球最多人用的社交媒體，同時亦成為了台灣人生活的一部分。台灣總人口有2,300，而台灣Facebook就有1,900萬個帳戶，是一個非常高的人口穿透力，亦代表台灣人很喜愛用Facebook。另外...",
      "image": require("../img/Blog_list_20.jpg")
  },
  {
      "key": 21,
      "title": "因安全因素，亞馬遜將移除這些鋰電池商品",
      "time": "2018年10月25日",
      "description": 
        "每年的旺季到來之前，亞馬遜都會對平臺上商品的安全性和相關證書進行審核。幾天前，亞馬遜郵件通知部分賣家，要求其在10月23日之前提供玩具類認證證書，否則將移除產品庫存。幾天後，鋰電池產品也收到了審核通知。 昨天，一位亞馬遜歐洲站賣家收到了一封“有關圈柱形鋰離子電池的重要資訊”...",
      "image": null
  },
  {
      "key": 22,
      "title": "日訂單增至10倍！這些中國“跨境優品”在西班牙很熱銷",
      "time": "2018年11月9日",
      "description": 
        "中國產品在這裡很受歡迎 作為歐洲第五大電商市場，西班牙雖然缺少像淘寶那樣涵蓋各個品類的大平臺，但是並不缺乏網購網站，電商發展還是較為成熟的。 Ecommerce Foundation的西班牙國家電商報告顯示，2016年，西班牙的電商交易額為239億歐元，有54%的西班牙人進...",
      "image": null
  },
  {
      "key": 23,
      "title": "全球電商龍頭亞馬遜與蘋果兩巨頭合作，聯手搶年末購物節商機",
      "time": "2018年11月29日",
      "description": 
        "美國亞馬遜(Amazon)於11日表示，已和蘋果(Apple)達成協議，將在全球販賣更多蘋果的產品，未來幾周會上線美國、英國、法國、義大利、西班牙、日本、印度等全球電商平台。包含最新的iPhone XS系列、iPhone...",
      "image": require("../img/Blog_list_23.jpg")
  },
  {
      "key": 24,
      "title": "沃爾瑪擠下蘋果成為全球第三大零售電商",
      "time": "2018年11月30日",
      "description": 
        "在電商上，老牌零售沃爾瑪(Walmart)常年被亞馬遜擠壓，實施了一份計畫長達8年，在將來還即將繼續擴大。自2016年亞馬遜和Whole Food合併以來，對於產業線上、線下零售商都有很大的衝擊。不過在今年8、10月份，沃爾瑪宣布分別以160億美元收購印度最大電商Flipk...",
      "image": require("../img/Blog_list_24.jpg")
  },
  {
      "key": 25,
      "title": "黑色星期五網購熱潮!銷售額破紀錄",
      "time": "2018年12月3日",
      "description": 
        "相對於中國阿里巴巴的雙11購物節，北美地區較盛行的自然是每年11月第四個星期五，俗稱的黑色星期五(Black Friday)的購物節活動，在這天不管是實體通路或網路銷售平台都會推出一系列促銷活動吸引消費者上門購物，藉此來響應即將到來的聖誕節。那麼黑色星期五是怎麼來的呢?...",
      "image": require("../img/Blog_list_25.jpg")
  },
  {
      "key": 26,
      "title": "AliExpress全球速賣通義大利商家大會,共享數字經濟新未來",
      "time": "2018年12月6日",
      "description": 
        "究竟中國人做生意可以做到多遠、多深? 在世界各地到處都可以看到中國人的足跡，舉凡在美國大城市都有唐人街(Chinatown) ，中國人主理的餐廳或各式各樣商店林立在街道。早期到海外創業的中國人可能經歷過許多艱辛的時刻，他們除了需要離鄉背井，還需要克服語言和文化上的差異。隨著...",
      "image": require("../img/Blog_list_26.jpg")
  },
  {
      "key": 27,
      "title": "香港創智營商博覽，不同的機遇和挑戰",
      "time": "2018年12月19日",
      "description": 
        "第二屆「創智營商博覽」(SmartBiz Expo)是由香港貿易發展局在灣仔會議展覽中心舉辦，主要針對中小型企業升級轉型、提升他們的競爭力和拓展全球市場的需要，提供一站式的支援。場內有不少展覽商參展，主要圍繞最新商業創新方案、應用科技、營運支援及管理等一站式平台。活動於12...",
      "image": require("../img/Blog_list_27.jpg")
  },
  {
      "key": 28,
      "title": "Buyippee X Rakuten 電商代購新策略講堂",
      "time": "2018年12月22日",
      "description": 
        "馬雲曾說過：「不做電子商務，五年後你會後悔。十年後，你要是在不做電子商務的話，那麼你將無商可務。」根據eMarketer調查網站指出預估2020年全球將有21.14億人口使用網路購物，然而將有超過9億的人口使用跨境購物，幾乎是總網購人口的一半。...",
      "image": require("../img/Blog_list_28.jpg")
  },
  {
      "key": 29,
      "title": "市值24.7兆新台幣 電商龍頭亞馬遜成為全球最高市值王",
      "time": "2019年1月8日",
      "description": 
        "受到中美貿易的影響，美股持續震盪，市值排名不斷洗牌，2018年底時，微軟取代蘋果登上美股市值第一的寶座，美國時間2019年1月7日美國股市延續漲勢，道瓊工業指數收長近百點，而電商龍頭亞馬遜漲幅3%市值約7990億美元，超越微軟(MSFT -...",
      "image": require("../img/Blog_list_29.jpg")
  },
  {
      "key": 30,
      "title": "德國運輸行業製造商Continental 發表機器物流狗 自駕車搭配機器狗作為快遞",
      "time": "2019年1月14日",
      "description": 
        "Continental 大陸集團雖在多數人的印象是輪胎品牌，不過他們在新一代無人車技術也相當積極投入，在今年 CES ，他們發表了一項一車兩用的無人車兼無人快遞的概念，透過把自駕無人車搭配機器狗，在無人車需求...",
      "image": require("../img/Blog_list_30.jpg")
  },
  {
      "key": 31,
      "title": "亞馬遜美國網站悄悄支援繁體中文 香港和台灣消費者購物配合Buyippee更方便",
      "time": "2019年2月12日",
      "description": 
        "對於喜歡在美國亞馬遜網站購物的朋友有福了!以往可能都需要請在美國的朋友幫你代購回香港和台灣，現在你到美國亞馬遜將會變得更簡單，因為現在終於支援中文介面了! 美國電商龍頭亞馬遜近期被發現開始支援繁體...",
      "image": require("../img/Blog_list_31.jpg")
  },
  {
    "key": 32,
    "title": "海外倉庫網絡 覆蓋全球",
    "time": "2019年5月2日",
    "description": 
      "現時美國以及英國的電子商務發展蓬勃，有電子商務平台修訂海外倉服務標準管理政策：前往英國站點的需求，在付款後5個工作日內不低於50%的送達掃描率；美國站點的需求, 在付款後12個工作日內不低於50%的送達掃描率。為了更有效接收客戶的貨件，我們在當地設立的自營倉庫，提升海外貨物...",
    "image": require("../img/Blog_list_32.jpg")
  },
  {
    "key": 33,
    "title": "Continental正式宣布與UPS攜手合作，為台灣賣家提供全球運輸方案",
    "time": "2023年8月1日",
    "description":
        "我們很榮幸地宣布，Continental和UPS已正式成為合作夥伴...",
    "image": require("../img/Blog_list_33.jpg")
  }
]