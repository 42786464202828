import React from 'react';
import img1 from '../../img/Article20_img1.jpg';
import img2 from '../../img/Article20_img2.jpg';

const Post20 = () => {
  return(
    <>
      <p>
        Facebook可以說是全球最多人用的社交媒體，同時亦成為了台灣人生活的一部分。台灣總人口有2,300，而台灣Facebook就有1,900萬個帳戶，是一個非常高的人口穿透力，亦代表台灣人很喜愛用Facebook。另外Facebook也有另一個社交軟體叫Instagram在台灣也是很受歡迎，連台灣總統蔡英文也有一個帳戶發佈他的日常動態。除此以外，Facebook亦是台灣最好的生意夥伴。台灣總統蔡英文表示台灣擁有完整的產業聚落和基礎建設，同時配合豐沛跟高水準的科技人才。表示台灣有堅強的硬體和環境，以及深厚的人才技術，能夠利用Facebook把台灣連接起來，讓全世界看到台灣的好。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        是次Facebook活動的標語是「Made by Taiwan Loved by the World」，公司有幸被邀請參加活動，成為在場唯一B2C的歐美物流服務供應商。是次活動邀請了數百位當地的跨境電商賣家，幾乎全部都有在Facebook銷售自家品牌的經驗。有不少客戶都對我們的服務抱有很大的興趣，希望把貨品賣到海外市場，包括歐洲、美國和澳洲等地區，透過Facebook把自已最好的產品介紹給全世界。
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        近年Facebook不斷創新以及升級他們的系統，由原本的純粹分享個人動態，到現在能夠在平台上寄賣貨品，與現代生活息息相關，如今都市人再也離不開Facebook這個不斷進步的社交媒體，而Facebook亦邁向電子商務一大步。Facebook相比其他網上銷售平台有更多的好處。首先，他們的銷售平台是建立於Facebook應用程式內，買賣家不需要另外下載或建立新帳戶。除了方便以外，他們的網絡與本身在Facebook上擁有的帳號打通，換句說話，只要擁有Facebook的帳號便能看到你的商品。而最重要的是在Facebook平台上寄賣貨品是完全不收取任何費用，這對於賣家來說絕對是一件可喜可賀的事情。
      </p>
    </>
  )
}

export default Post20;