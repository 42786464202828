import React from 'react';
import img1 from '../../img/Article10_img1.jpg';

const Post10 = () => {
  return(
    <>
      <p>
        上文已有提及到東南亞的金流，身為跨境電商的你，不但要對當地的金流了解，你還需要知道東南亞的物流資訊。由於東南亞地區的電商業務還處於發展中階段，加上國家大多是由不同海島組成，導致物流服務還遠遠不夠歐美國家成熟。根據世界銀行在2016年的公佈的《物流績效指數》顯示，東盟五國的排名如下：（一）新加坡（二）馬來西亞（三）泰國（四）印尼（五）菲律賓。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        <span>新加坡</span><br />
        新加坡相比其他東南亞地區為較成熟的電子商務市場，相對上物流配送服務亦較成熟。目前物流業已成為新加坡經濟的重要部分，物流業對新加坡整體GDP的貢獻將逐漸增加，成為國家支柱產業之一。在新加坡廣為人知的物流服務相信非擁有超過150年歷史的新加坡郵政(Sing Post) 莫屬。新加坡郵政不但為個人消費者提供輪送服務，同時亦針對企業用戶提供貨物運輸、倉儲、遞送和退換貨等服務。
      </p>
      <p>
        <span>馬來西亞</span><br />
        馬來西亞的物流發展亦不錯，由於對地基礎建設已成熟，加上政府的大力推動，現時已有電大主要品牌，包括由政府投資設立的大馬郵政(Pos Malaysia) 和由新加坡郵政投資的GD Express。在2016年阿里巴巴創辦人馬雲擔任大馬的數碼經濟委員會的顧問後，大馬政府揚言在未來消費者會在1天內獲得訂單和貨品。
      </p>
      <p>
        <span>泰國</span><br />
        由於電子商務在東南亞的發展，導致泰國的物流服務亦漸趨成熟。在泰國已有不少第三方物流支援尾程服務，確保最後一公里順利交付。這對跨境電商賣家來說是一件可喜可賀的事，表示物流供應商需在價格戰中承受較低的利潤，跨境電商能夠從中選擇性價比較高的服務。
      </p>
      <p>
        <span>印尼</span><br />
        排行倒數第二的印尼運費主要是敗在島與島之間的運輸，在最後一公里的派送服務困難重重。運輸業占印尼的GDP 26%，是新加坡和大馬的兩倍。在世界銀行刊登的《物流績效指數》指出，印尼的運輸基建以及物流能力遠比其他國家發展緩慢。現時印尼政府正推行全國政策為改善國家的物流服務，期望會在不久的將來得到成效，為印尼的電子商務賣家帶來便利。
      </p>
      <p>
        <span>菲律賓</span><br />
        目前菲律賓在物流服務的表現較為乏善足陳，尤其是在最後一公里的派送服務，該國欠缺物流設備和清關效率慢的關系，大大影響到當地的電子商務發展。與印尼相同，菲律賓由多個島嶼組成，雖說是人口第二多的東盟國家，人口亦相對上年輕，但在電子商務的發展仍充滿挑戰性。
      </p>
    </>
  )
}

export default Post10;