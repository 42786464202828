import React from 'react';
import ServiceStyle from '../styles/pages/service.module.scss';
import Service_DL_1 from '../img/Service_DL_1.png';
import Service_DL_2 from '../img/Service_DL_2.png';
import Service_DL_3 from '../img/Service_DL_3.png';
import Service_DL_4 from '../img/Service_DL_4.png';
import Service_DL_5 from '../img/Service_DL_5.png';
import Service_Ful_1 from '../img/Service_Ful_1.png';
import Service_Ful_2 from '../img/Service_Ful_2.png';
import Service_Ful_3 from '../img/Service_Ful_3.png';
import Service_Ful_4 from '../img/Service_Ful_4.png';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

const Service = () => {
  const { t, i18n } = useTranslation();
  return(
    <>
      <MetaTags>
        <title>
          {  
            i18n.language === 'en' || i18n.language === 'jp' ?
            'Continental Global Service Ltd - One-Stop eCommerce Logistics Service' :
            'Continental Global Service Limited - 一站式跨境電商物流 | 服務範疇'
          }
        </title>
      </MetaTags>
      <div className={ ServiceStyle.service }>
        <div className="banner-color">
          <h1 className="wrap">{ t('page.service') }</h1>
        </div>
        <div className="wrap content">
          <div className={ ServiceStyle.banner }>
            <h4>{ t('service.warehouse.text') }</h4>
          </div>
          <div className={ ServiceStyle.content }>
            <h3>{ t('service.directLine.title') }</h3>
            <ul className={ ServiceStyle.item }>
              <li>
                <img src={ Service_DL_1 } alt="Service_DL_1" />
                <p>{ t('service.directLine.img.1') }</p>
              </li>
              <li>
                <img src={ Service_DL_2 } alt="Service_DL_2" />
                <p>{ t('service.directLine.img.2') }</p>
              </li>
              <li>
                <img src={ Service_DL_3 } alt="Service_DL_3" />
                {
                  i18n.language === 'en' || i18n.language === 'jp' ?
                  <p>{ t('service.directLine.img.3') }</p>
                  :
                  <p>倉庫根據貨件<br />目的地分類</p>
                }
              </li>
              <li>
                <img src={ Service_DL_4 } alt="Service_DL_4" />
                <p>{ t('service.directLine.img.4') }</p>
              </li>
              <li>
                <img src={ Service_DL_5 } alt="Service_DL_5" />
                <p>{ t('service.directLine.img.5') }</p>
              </li>
            </ul>
            <p>
              { t('service.directLine.content') }
            </p>
          </div>
          <div className={ ServiceStyle.content }>
            <h3>{ t('service.fulfillment.title') }</h3>
            <ul className={ ServiceStyle.item_ful }>
              <li>
                <img src={ Service_Ful_1 } alt="Service_Ful_1" />
                {
                  i18n.language === 'en' || i18n.language === 'jp' ?
                  <p>{ t('service.fulfillment.img.1') }</p>
                  :
                  <p>買家在銷售<br />平台下單</p>
                }
              </li>
              <li>
                <img src={ Service_Ful_2 } alt="Service_Ful_2" />
                {
                  i18n.language === 'en' || i18n.language === 'jp' ?
                  <p>{ t('service.fulfillment.img.2') }</p>
                  :
                  <p>Continental<br />系統接收訂單</p>
                }
              </li>
              <li>
                <img src={ Service_Ful_3 } alt="Service_Ful_3" />
                {
                  i18n.language === 'en' || i18n.language === 'jp' ?
                  <p>{ t('service.fulfillment.img.3') }</p>
                  :
                  <p>Continental<br />海外倉庫出貨</p>
                }
              </li>
              <li>
                <img src={ Service_Ful_4 } alt="Service_Ful_4" />
                <p>{ t('service.fulfillment.img.4') }</p>
              </li>
            </ul>
            <p>
              { t('service.fulfillment.content') }
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Service;