import React from 'react';
import img1 from '../../img/Article14_img1.jpg';
import img2 from '../../img/Article14_img2.jpg';
import img3 from '../../img/Article14_img3.jpg';
import img4 from '../../img/Article14_img4.jpg';

const Post14 = () => {
  return(
    <>
      <p>
        Amazon Prime Day賣家都忙著備貨，但同時Amazon卻收到一封由美國國會議員Keith Ellison的信，信中批擊Amazon容許賣家提倡和宣傳仇恨、暴力、種族主義、性和宗教，縱容在平台上出售有關商品，包括兒童玩具、衣服和Kindle書籍。同時，Ellison指出自從美國總統Donald Trump上任後陸續出現該類型商品，Amazon未能阻止仇恨團體和南方貧困法律中心(Southern Poverty Law Center) 在其平台上賺錢。無論是亞馬遜是否故意拒絕執行公司提出的「禁止出售種族主義產品」政策，抑或是其龐大的公司規模使無法自我監管，Amazon都必須立即停止與種族主義暴力的團體有關業務。Allison在信中列出多項違反政策的商品，包括含有納粹德國的象徵物品、或是分化美國的國旗等。
      </p>
      <img src={ img1 } alt="img1"/>
      <figcaption>
        Amazon沒有刪除一些納粹物品，例如納粹黨武裝派別武裝黨衛隊官員的帽子。
      </figcaption>
      <img src={ img2 } alt="img2"/>
      <figcaption>
        根據南方貧困法律中心的說法，自1945年以來，納粹標誌已成為最重要和最臭名昭著的仇恨象徵，反猶太主義和白人至上主義。
      </figcaption>
      <img src={ img3 } alt="img3"/>
      <figcaption>
        即使其賣家政策禁止此類物品，Amazon尚未刪除具有美利堅聯盟國旗幟的產品。
      </figcaption>
      <p>
        Amazon收到信件後，隨即在網上平台刪除有關商品，公共政策副總裁Brian Huseman亦在7月31日向Ellison回信，表明Amazon是不允許宣傳美化仇恨、暴力、種族、性或宗教的產品在平台上銷售，除了刪除有關產品外，亦將違規賣家進行永久封鎖帳號。
      </p>
      <img src={ img4 } alt="img4"/>
      <p>
        經過這次事件，所有在Amazon銷售任何有關宗教或種族的賣家要注意了。不要隨意使用有關宗教神靈或象徵的圖案及符號，否則被信徒認為你的產品褻瀆了他們的信仰，你的產品需要下架之餘，帳號亦有可能被永久封鎖。
      </p>
    </>
  )
}

export default Post14;