import React from 'react';
import img1 from '../../img/Article6_img1.jpg';

const Post6 = () => {
  return(
    <>
      <p>
        談到電商，普羅大眾的印象都是國內的銷售平台，但其實香港的電商產業已老早發展，但由於本地電商市場細，發展不蓬勃，加上經營困難，令我們都忽略了它。事實上，香港是一個自由港的身分，奉行自由貿易政策，並無設置任何貿易壁壘，進出香港的貨品毋須繳付關稅，應能為本地電商業帶來方便。電商規模廣泛，涵蓋層面廣，由B2B,，B2C，到B2B2C甚至O2O。而香港電商可分為三大版塊：1）本地電商，規模較細 2）進口電商，以中國大陸為主，透過香港中轉貨品到中國大陸 3）出口電商，以傳統歐美買家為市場。
      </p>
      <p>
        香港雖說是自由港，本地電子商務卻相比其他市場細，主要原因可分為以下幾點：
      </p>
      <p>
        <span>1） 市場規模細</span><br />
        據官方統計，全香港人口約735萬，即使全港市民都擁有網購習慣，市場規模仍比不上內地一個大型城市。亦因為市場細的關係，香港的本地網上購物平台亦寥寥可數，最常見的HKTV Mall和Carousell都是近幾年才開始發展。而知名大型網購平台亦不願意投放人力和資源開發香港網購市場。
      </p>
      <p>
        <span>2） 物流價錢昴貴</span><br />
        物流價錢的影響亦是重要的一環。香港物流費相比國內普遍較高，低單價產品則較難處理。加上香港物流及快遞公司局限只派送到地沿線，「步兵」會於MTR交收，達至低成本的效果，可惜至今香港仍有很多地方仍未被地鐵覆蓋，除此之外，派送地點多以Office為單位，為消費者帶來不便。況且香港零售實體店在街上隨處可見，久而久之香港人對零售實體店養成依賴性，難以發展網上市場。
      </p>
      <p>
        <span>3） 香港成本較高</span><br />
        在香港這個彈丸之地，眾所周知有嚴重的土地問題。地少人多造成土地供應嚴重不足。倉儲對電子商務賣家來說是不可或缺的一項開支，持續上漲的倉庫租金為賣家造成一定的壓力。而同時間人力成本亦不下於租金，因此香港本地電商雖說正在發展，但規模相比之下較細。
      </p>
      <p>
        由此可見，網購生態與物流系統是互相影響的關係，若然能夠改善物流帶來的問題，相信香港電商發展定必越來越蓬勃。
      </p>
      <img src={ img1 } alt="img1"/>
      <figcaption>
        以上由新城電台訪問節錄
      </figcaption>
    </>
  )
}

export default Post6;