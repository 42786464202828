import React from 'react';
import { Link } from 'react-router-dom';
import LocationStyle from '../styles/pages/location.module.scss';
import Location_country from '../img/Location_country.png';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import Location_warehouse_001 from '../img/Location_warehouse_001.jpg';
import Location_warehouse_002 from '../img/Location_warehouse_002.jpg';
import Location_warehouse_003 from '../img/Location_warehouse_003.jpg';
import Location_warehouse_004 from '../img/Location_warehouse_004.jpg';
import Location_warehouse_005 from '../img/Location_warehouse_005.jpg';
import Location_warehouse_006 from '../img/Location_warehouse_006.jpg';
import Location_warehouse_007 from '../img/Location_warehouse_007.jpg';
import Location_warehouse_008 from '../img/Location_warehouse_008.jpg';
import Location_warehouse_009 from '../img/Location_warehouse_009.jpg';
import Location_warehouse_010 from '../img/Location_warehouse_010.jpg';
import Location_warehouse_011 from '../img/Location_warehouse_011.jpg';
import Location_warehouse_012 from '../img/Location_warehouse_012.jpg';
import Location_warehouse_013 from '../img/Location_warehouse_013.jpg';
import Location_warehouse_014 from '../img/Location_warehouse_014.jpg';
import Location_warehouse_015 from '../img/Location_warehouse_015.jpg';
import Location_warehouse_016 from '../img/Location_warehouse_016.jpg';
import Location_warehouse_017 from '../img/Location_warehouse_017.jpg';
import Location_warehouse_018 from '../img/Location_warehouse_018.jpg';
import Location_warehouse_019 from '../img/Location_warehouse_019.jpg';
import Location_warehouse_020 from '../img/Location_warehouse_020.jpg';
import Location_warehouse_021 from '../img/Location_warehouse_021.jpg';
import Location_warehouse_022 from '../img/Location_warehouse_022.jpg';
import Location_warehouse_023 from '../img/Location_warehouse_023.jpg';
import Location_warehouse_024 from '../img/Location_warehouse_024.jpg';
import Location_warehouse_025 from '../img/Location_warehouse_025.jpg';
import Location_warehouse_026 from '../img/Location_warehouse_026.jpg';
import Location_warehouse_027 from '../img/Location_warehouse_027.jpg';
import Location_warehouse_028 from '../img/Location_warehouse_028.jpg';
import Location_warehouse_029 from '../img/Location_warehouse_029.jpg';

const Location = () => {
  const { t, i18n } = useTranslation();
  const images = [
    {
      original: Location_warehouse_001,
      thumbnail: Location_warehouse_001,
    },
    {
      original: Location_warehouse_002,
      thumbnail: Location_warehouse_002,
    },
    {
      original: Location_warehouse_003,
      thumbnail: Location_warehouse_003,
    },
    {
      original: Location_warehouse_004,
      thumbnail: Location_warehouse_004,
    },
    {
      original: Location_warehouse_005,
      thumbnail: Location_warehouse_005,
    },
    {
      original: Location_warehouse_006,
      thumbnail: Location_warehouse_006,
    },
    {
      original: Location_warehouse_007,
      thumbnail: Location_warehouse_007,
    },
    {
      original: Location_warehouse_008,
      thumbnail: Location_warehouse_008,
    },
    {
      original: Location_warehouse_009,
      thumbnail: Location_warehouse_009,
    },
    {
      original: Location_warehouse_010,
      thumbnail: Location_warehouse_010,
    },
    {
      original: Location_warehouse_011,
      thumbnail: Location_warehouse_011,
    },
    {
      original: Location_warehouse_012,
      thumbnail: Location_warehouse_012,
    },
    {
      original: Location_warehouse_013,
      thumbnail: Location_warehouse_013,
    },
    {
      original: Location_warehouse_014,
      thumbnail: Location_warehouse_014,
    },
    {
      original: Location_warehouse_015,
      thumbnail: Location_warehouse_015,
    },
    {
      original: Location_warehouse_016,
      thumbnail: Location_warehouse_016,
    },
    {
      original: Location_warehouse_017,
      thumbnail: Location_warehouse_017,
    },
    {
      original: Location_warehouse_018,
      thumbnail: Location_warehouse_018,
    },
    {
      original: Location_warehouse_019,
      thumbnail: Location_warehouse_019,
    },
    {
      original: Location_warehouse_020,
      thumbnail: Location_warehouse_020,
    },
    {
      original: Location_warehouse_021,
      thumbnail: Location_warehouse_021,
    },
    {
      original: Location_warehouse_022,
      thumbnail: Location_warehouse_022,
    },
    {
      original: Location_warehouse_023,
      thumbnail: Location_warehouse_023,
    },
    {
      original: Location_warehouse_024,
      thumbnail: Location_warehouse_024,
    },
    {
      original: Location_warehouse_025,
      thumbnail: Location_warehouse_025,
    },
    {
      original: Location_warehouse_026,
      thumbnail: Location_warehouse_026,
    },
    {
      original: Location_warehouse_027,
      thumbnail: Location_warehouse_027,
    },
    {
      original: Location_warehouse_028,
      thumbnail: Location_warehouse_028,
    },
    {
      original: Location_warehouse_029,
      thumbnail: Location_warehouse_029,
    }
  ];

  return(
    <>
      <MetaTags>
        <title>
          {  
            i18n.language === 'en' || i18n.language === 'jp' ?
            'Continental Global Service Ltd - One-Stop eCommerce Logistics Service' :
            'Continental Global Service Limited - 一站式跨境電商物流 | 倉庫網絡'
          }
        </title>
      </MetaTags> 
      <div className={ LocationStyle.location }>
        <div className="banner-color">
          <h1 className="wrap">{ t('page.location') }</h1>
        </div>
        <div className="wrap content">
          <div className={ LocationStyle.content }>
            <p>
              { t('location.text.1') }<br />{ t('location.text.2') }
            </p>
            <div className={ LocationStyle.mapImg }>
              <div>
                <img src={ Location_country } alt="Location_country" />
                {
                  i18n.language === 'en' || i18n.language === 'jp' ?
                  null :
                  <Link to="/logistic">
                    <span>專線物流</span>
                  </Link>
                }
              </div>
            </div>
            <ImageGallery 
              items={images} 
              showFullscreenButton={false}
              showPlayButton={false}
              // autoPlay={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Location