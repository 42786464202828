import React from 'react';
import img1 from '../../img/Article13_img1.jpg';

const Post13 = () => {
  return(
    <>
      <p>
        目前，跨境電商雖然仍呈現迅猛發展的態勢，但是生意卻越來越難做。一方面是平台門檻的提高對賣家增添不少限制；另一方面，行業競爭加劇導致中小賣家的利潤空間縮減。來自香港的跨境收款黑馬平台Vcan，提供封頂0.5%收費，令電商賣家備貨無憂，提升賣家競爭力。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        在8月1日「創新金融鏈接全球」─ 跨境電商的金融峰會暨Vcan 2018品牌戰略發佈會於深圳舉行，針對跨境電商行業痛點帶出三大產品系列「新金融」、「新科技」、「新力量」，幫助賣家解決融資、企業海外細則及旺季備貨的問題。 Vcan致力為跨境賣家打造開放的電商生態系統，透過領先的科技推進，針對跨境賣家提供安全、高效、便捷的環球收款及金融服務。
      </p>
      <p>
        是次大會亮點頗多，集結主流平台負責人、以及大型賣家，為賣家分析熱賣產品趨勢以及讓中型賣家實現利潤最大化的運營方式，還有橫琴政府代表解讀大灣區優惠政策、香港頂尖金融專家包括國際四大會計所的合夥人、資本市場代表分享跨境細則以及企業資本化策略、分析當前國際金融形勢、匯率走勢以致中美貿易戰如何影響跨境電商的發展等等，內容都關乎每個電商賣家的利益。Vcan的收款平台十分人性化，切合用家需求，讓賣家可以輕鬆管理多個網店。節省用戶時間之餘，能夠提專注力投放到開拓市場上。
      </p>
      <p>
        身為Vcan其中一個客戶，我認為雖然Vcan仍是一個新平台，但服務並不馬虎。首先它的回款費用非常低亦無任何隱藏收費，令賣家安心使用服務。另外，所有手續都非常簡便，全部都能在網上進行操作，對於電商流程來說非常方便。與此同時，他們為電商的流水帳提供貸款新服務，對於我們來說很新穎。因為大部分的電商都需要透過傳統的渠道，包括銀行的融資，抑或一些傳統Profit and Loss（損益表）去體現電商的生意，有延遲收款的風險。但若透過流水帳，每月的生意可即時貸款到帳，對於電商來說是非常大的幫助。
      </p>
    </>
  )
}

export default Post13;