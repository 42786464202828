import React from 'react';
import img1 from '../../img/Article18_img1.jpg';

const Post18 = () => {
  return(
    <>
      <p>
        “我們同阿裡巴巴的簽約是中俄間極具代表性的合作，將加速為俄羅斯電商發展帶來一系列改變。”在9月12日東方經濟論壇全體會議上，俄羅斯總統普京特別提到了阿裡巴巴與俄三巨頭成立合資公司的消息，強調此舉是中俄兩國深化共建“一帶一路”和歐亞經濟聯盟對接的高效合作成果。
      </p>
      <p>
        這是在為期3天的東方經濟論壇上普京第二次“點贊”阿裡巴巴。在11日他與阿裡巴巴董事局主席馬雲的會面中，普京就曾明確指出，俄羅斯政府將全力支持阿裡巴巴在當地發展，並對馬雲提出的“數字絲綢之路”建設給予了高度肯定。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        當日，在阿裡巴巴集團董事局主席馬雲與USM集團CEO Ivan Streshinskiy的共同見證下，阿裡巴巴與俄羅斯直接接投資基金，俄羅斯通信、科技領域兩大巨頭Megafon和Mail.ru簽署戰略合作，組建名為AliExpress Russia的合資公司。消息一出，即讓MegaFon在莫斯科證券交易所的股票價值同時從550上漲至558盧布，Mail.ru在倫敦證交所的全球存托憑證大漲12％。
      </p>
      <p>
        合作的消息同樣引發了俄羅斯本地及全球主流媒體的高度關注。俄著名資訊網站Lenta.ru分析認為，這項中俄科技業巨頭的合作之所以引發普京如此關注，是因其展現了兩國在現金領域的創新合作，這樣的商業模式領先歐美，且未來發展極具想像空間。俄羅斯業內人向媒體透露，新成立的合資公司勢必將領跑俄羅斯國內經濟增長。
      </p>
      <p>
        俄羅斯經濟新聞通訊社《Prime》撰文指出，新的合作方式未來將讓阿裡巴巴及其合作夥伴 成為俄羅斯的“新技術領導者”。俄羅斯《生意人報》的報導則稱這是俄羅斯互聯網領域迄今最大合作。報導強調，這家亞洲最有價值的公司通過與俄羅斯巨頭合作，將直接觸達擁有近億互聯網用戶的市場，而俄羅斯直接投資基金這個“國家隊”的極強背書，亦證明俄羅斯政府對此項合作的長期看好。
      </p>
      <p>
        英國《金融時報》分析認為，不論是中國企業與俄方協作建設大型合資企業，亦或是在一個擁有超過1.4億人口的國家深耕電商+社交領域市場，阿裡巴巴此舉在全球範圍裡都具有程碑式的意義。
      </p>
      <p>
        素有“投資百科”之稱的美國媒體Investopedia在其名為《阿裡巴巴與俄羅斯夥伴共建“網上絲綢之路”》的文章中指出，阿裡巴巴與俄羅斯三巨頭強強聯手，恰是回應“網上絲綢之路”的一項重要舉措。Investopedia認為，“一帶一路”倡議一直鼓勵中國企業在海外發展電子商務，及移動通信和支付業務，阿裡巴巴在這些領域一直處於領先地位。此外，通過與印尼、馬來西亞及全球多國合作，阿裡巴巴正在幫助更多的合作夥伴實現數位化經濟，帶動小企業發展並促進線上雙向貿易。文章援引馬雲的話指出，“網上絲綢之路”正在打破傳統貿易壁壘，為全球的年輕人和中小企業提供平等包容的發展機會。
      </p>
      <p style={{ textAlign: 'right' }}>
        （編輯：賈銳傑）
      </p>
      <p>
        轉載至 <a href="http://www.ennews.com/article-9993-1.html" target="_blank" rel="noreferrer">http://www.ennews.com/article-9993-1.html</a>
      </p>
    </>
  )
}

export default Post18;