import React from 'react';
import img1 from '../../img/Article2_img1.jpg';

const Post2 = () => {
  return(
    <>
      <p>
        在上一篇電商日誌提及我們在4月23日與台灣知名的輔仁大學簽訂產學合作備忘錄，該校將協助挑選來自外文系的優秀學員加入本公司擔任翻譯員。我們近日更有幸獲得輔大外語學院的邀請，委派一位代表加入該學院產業實習委員會擔任委員，檢討學院產業實習計劃的成效以及未來的規劃。
      </p>
      <img src={ img1 } alt="img1"/>
      <p>
        其實目前已有多名實習生在我們的台灣分公司開拓他們事業，他們都曾在外國生活，對當地的風土人情、語言文化等都有著親身體驗，期間亦結交不少朋友，掌握很多第一手資訊，所以相信他們能夠勝任產品翻譯的工作。談及與輔仁大學的合作，其實亦相當巧合，事源我們於2014年在台灣成立分公司的時候，招聘的員工多是來自輔仁大學的畢業生，因爲電商行業廣闊的發展前景，在一衆“師兄師姐”的介紹與推薦下，越來越多的輔仁大學的畢業生加入我們的大家庭。如今與輔仁大學正式簽訂了合作備忘錄，更將我們與輔大的關係推上一個新的臺階。
      </p>
      <p>
        當然我們不會自滿，更不會固步自封，我們會致力與更多專上學院建立伙伴關係，不但為同學帶來實習機會，讓他們知道電商行業的發展潛力，推動整個行業的發展外，亦為我們招納更多人才，開拓包括電商翻譯等多項新業務，為客戶帶來更多元化及全面的專業電商服務。
      </p>
    </>
  )
}

export default Post2;